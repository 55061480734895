import React from "react";
import { Box, Typography, Grid, styled ,Button,Divider, AccordionDetails, AccordionSummary, Accordion} from "@material-ui/core";
import { eyeIcon, likeIcon } from "../../blocks/carouseldisplay/src/assets";
import { featureIcon, downloadIconFiiled, profileIcon } from "../../blocks/cfproposalmanagement/src/assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { webStyle2 ,CustomBox,proposalStyle,styles} from "../../blocks/cfproposalmanagement/src/Cfproposalmanagement.web";
const SkillButton = styled(Button)({
    fontFamily: "Inter , sans-serif",
    marginTop: "24px",
    backgroundColor: "rgb(50, 50, 50)",
    textTransform:'none',
    fontSize: "12px",
    fontWeight: 600,
    linehHeigth: "14.52px",
    color: "#FFF2E3",
    borderRadius: "21px",
    border: " 1px solid #FFF2E3",
    padding: "11px 22px 11px 22px",
    width: "max-content",
    height: "37px",
  });
  const config = require("../../framework/src/config.js")
  import '../../blocks/cfproposalmanagement/src/style.css'

export const ProposalComponent = ({ shareProfile,projectEstimate,previewData,showCollection,pitchData,
hourlyRate,dateData,isFileAdded,linkList,files,openImageInNewTab,downloadImage,workOppertunityData,Question,project_timeline,project_timeline_type,hours,rateHourlyClient,rateType}: any) => (
<>
<Box>
    <Typography className="submitted-preview">
      Submitted work
    </Typography>
{shareProfile &&
 <Typography className="card-preview-username" >
Shared full profile of Deziners Knot
</Typography>
}
{showCollection &&
<Typography className="card-preview-username" >
No projects selected
</Typography>}
    {previewData && previewData.map((item: any) => (
      <Box key={item.collection_name}>
        <Box className="collection-view-preview">
          {/* <Typography className="submitted-preview label-preview">
            Label name
          </Typography> */}
          <Divider style={webStyle2.dividerStyle} />
          <Typography className="collection-preview">
            {item.collection_name}
          </Typography>
          
          <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '22px' }}>
            {item.data.map((project: any, index: number) => (
              <Box
                key={index}
                style={{
                  width: '380px',
                  borderRadius: '8px',
                  gap: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <img
                  src={`${config.baseURL}${project.cover_image_url}`}
                  style={{ width: '100%', height: '260px', borderRadius: '8px' }}
                  alt={project.title}
                />
                
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Box>
                    <Typography className="card-preview-title">
                      {project.project_name}
                    </Typography>
                    <Typography className="card-preview-username">
                      {project.username}
                    </Typography>
                  </Box>
                  
                  <Box style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                    <Box className="card-preview-subheading">
                      {project.views}
                      <img className='eyeIcon' src={eyeIcon} alt='eye icon' />
                    </Box>
                    <Box className="card-preview-subheading">
                      {project.likes}
                      <img className='likeIcon' src={likeIcon} alt='like icon' />
                    </Box>
                    <Box className="card-preview-subheading">
                      {project.saved_count}
                      <img className='featureIcon' src={featureIcon} alt='feature icon' />

                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    ))}
  </Box>


            

                <Box style={{ marginTop: '30px' }} >
                  <Typography  className="submitted-preview">
                    Pitch
                  </Typography>
                  <Typography className="card-preview-username">
                   {pitchData}
                  </Typography>
                </Box>
                <Box style={{ marginTop: '30px' }} >
                  <Typography  className="submitted-preview">
                    Hourly Rate <span style={{fontSize:'12px', color: 'rgba(102, 98, 92, 1)'}}>
                      ( Your offer: ₹ {rateHourlyClient} {rateType()})
                    </span>
                  </Typography>
                  <Box style={webStyle2.btnHourBox} >
                        <Typography style={{...webStyle2.priceHour,fontSize:'14px'}}>
                        ₹ {hourlyRate} {" "}  pr/hr
                        </Typography>
                      </Box>
                      <Grid container spacing={2} alignItems="center" style={{ marginBottom: 2, alignItems: "start",marginTop:'25px' }}>
                  <Grid item xs={12} md={3}>
                    <Box style={{...styles.txtfield,width:'350px',paddingInline:'8px',alignItems:'unset',height:'80px'}} >
                      <Grid container >
                        <Grid item xs={8} style={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
                        <Typography style={{...proposalStyle.monyStyle,fontSize:'14px',marginBottom:"4px"}}>Overall project est.</Typography>
                        <Grid container style={{borderTop:'1.16px solid rgb(30, 25, 25)',marginTop:'6px'}}>
<Grid item xs={5}  style={{display:'flex',justifyContent:'center',alignItems:'center',height:'40px',borderRight:'1.16px solid rgb(30, 25, 25)'}}>
<Typography style={{...proposalStyle.monyStyle,fontSize:'14px', color: 'rgba(102, 98, 92, 1)'}}>
 {project_timeline} {project_timeline_type}
  </Typography>

 
  </Grid>
  <Grid item xs={7}   style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
  <Typography style={{...proposalStyle.monyStyle,fontSize:'14px', color: 'rgba(102, 98, 92, 1)'}}>
    {hours} hours a week
  </Typography>
  </Grid>
                        </Grid>

                        </Grid>

                        <Grid item xs={4} style={{display:'flex',alignItems:'center',justifyContent:'center',borderLeft:'1.16px solid rgb(30, 25, 25)'}}>
                        <Typography  style={{...proposalStyle.monyStyle,fontSize:'14px'}}>₹ {projectEstimate.toFixed(2)}</Typography>

</Grid>
                      </Grid>
                     
                    </Box>
                  </Grid>
                </Grid>
                </Box>
                <Box
                        style={{
                          ...webStyle2.btnBox,
                          boxSizing: "border-box",
                          flexDirection: "column",
                        }}
                      >
                        <Typography style={{ ...webStyle2.skillTag }}>
                          Starting date
                        </Typography>
                        <Box
                          style={{ ...webStyle2.btnContainer, flexWrap: "wrap" }}
                        >
                          
                                <SkillButton
                                >
                               {dateData.format('MMMM D, YYYY')}
                                </SkillButton>
                        
                        </Box>
                      </Box>
                      {(isFileAdded() || linkList.length>0  ) &&<Box
                        style={{
                          ...webStyle2.btnBox,
                          boxSizing: "border-box",
                          flexDirection: "column",
                        }}
                      >
                        <Typography style={{ ...webStyle2.skillTag ,marginBottom:'20px'}}>
                       Additional files
                        </Typography>
                      {isFileAdded() && <CustomBox 
                      className='flex-data horizontal-scroll' >{files.map((item:any, index:number) => (<CustomBox
                       key={index} className='filename-container horizontal-scroll'
                       style={{
                        flexDirection: "row",
                        display: 'flex',
                        marginBottom:'10px',
                        gap: '8px',
                      }
                                            } >
                     <CustomBox style={{margin:'unset',
                     width:'215px',
                     whiteSpace: 'nowrap',
                     overflow: 'hidden',
                     textOverflow: 'ellipsis',
cursor:'pointer',
color: '#fff',
  padding: '13px 15px',
  borderRadius: '10px',
  backgroundColor: '#252525'
                     }} 
                     data-test-id="filesopenImage2"

                     onClick={() => openImageInNewTab(item.data)}
                     className='filename-container-elm hint-text'>{item.filename}</CustomBox>
                      <img src={downloadIconFiiled} style={{width:'14px',cursor:'pointer'}} 
                      data-test-id="download-fileee"
                      onClick={()=>{
                      downloadImage(item)
                      }}/>

                    </CustomBox>))}</CustomBox>}
                    {linkList && linkList.map((item:any, index:number) => (<CustomBox 
                    key={`${index}`}
                    style={{ display: 'flex', alignItems: 'center',
                        flexDirection: "row",
                        gap: '30px',
                     }}>
                      <Typography
                     data-test-id="filesopenLink2"

                       onClick={()=>{
                        const newTab = window.open( item, '_blank');
        
                        if (newTab) {
                          newTab.focus();
                        }
                       }} style={{margin:'unset',
                     fontFamily: 'Inter', 
                     fontSize: '16px', 
                     fontWeight: 400, 
                     lineHeight: '19.36px', 
                     textAlign: 'left' ,
                     color:"rgba(82, 138, 223, 1)",
                     width:'245px',
                     whiteSpace: 'nowrap',
                    cursor:'pointer',
                    
                     overflow: 'hidden',
                     textOverflow: 'ellipsis'
                      

                      }}>{item}</Typography>
                   
                     
                    </CustomBox>))}
                    </Box>}
                    <Box style={{ marginTop: '30px' }}>
                      {
                       workOppertunityData.attributes?.question && workOppertunityData.attributes.question.map((item:any,index:number)=>{
                            return <Box><Typography   style={{
                              ...webStyle2.description,
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              whiteSpace: "pre-line",
                              marginBottom:'10px'
                            }}>
{index + 1}. {item}
                            </Typography>
                            <Typography   style={{
                              ...webStyle2.description,
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              whiteSpace: "pre-line",
                              fontSize:'14px',
                              marginRight:'30px'
                            }}>
{Question[index]}
                            </Typography>
                            </Box>
                          })
                      }
                    </Box>
</>
);
export const ProfileView=({profileData,hourlyRate}:any)=>{
  const getProfileDetails = () => {
     
     return profileData.profile_data.profile_details?.background_color ? (
       <div
         className="img-view-preview"
         style={{ backgroundColor: profileData.profile_data.profile_details.background_color }}
       />
     ) : (
       <img src={profileIcon} className="img-view-preview" alt="Default Profile" />
     );
   };
   return <>
       <Box className="profile-view-preview">
                 
                 {
   profileData.profile_data.profile_details?.photo ? (
     <img
       src={`${config.baseURL}${profileData.profile_data.profile_details.photo}`}
       className="img-view-preview"
       alt="Profile"
     />
   ) : getProfileDetails()
 }
 
                   <Box className="profile-name-preview2">
                     <Typography className="profile-name-preview">
                     {profileData?.profile_data?.profile_details.user_name}
                     </Typography>
                     <Typography className="profile-address-preview">
                     {profileData?.profile_data?.profile_details?.city}
                     </Typography>
                   </Box>
                 </Box>
                 <Grid container spacing={4} style={{ paddingBottom: '20px' }}>
                   <Grid item xs={12} sm={12} md={5} lg={5} style={{ display: 'flex', flexDirection: 'row' }}>
                     <Box className="part-sub-preview">
                       <Typography className="part-sub-heading">
                         Views
                       </Typography>
                       <Typography className="part-sub-value">
                       {profileData?.profile_data.profile_views}
 
                       </Typography>
                     </Box>
                     <Box className="part-sub-preview">
                       <Typography className="part-sub-heading">
                         Likes
                       </Typography>
                       <Typography className="part-sub-value">
                       {profileData?.profile_data.profile_likes}
 
                       </Typography>
                     </Box>
                     <Box className="part-sub-preview">
                       <Typography className="part-sub-heading">
                         Submitted rate
                       </Typography>
                       <Typography className="part-sub-value">
                       {hourlyRate}
 
                       </Typography>
                     </Box>
                     <Box className="part-sub-preview">
                       <Typography className="part-sub-heading">
                         Ratings
                       </Typography>
                       <Box className="rating-view">
 
                         <Typography className="part-sub-value">
                         {profileData?.profile_data?.profile_details.ratings}
 
                         </Typography>
                         <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M7.54104 10.6828L11.4409 12.9767C11.7276 13.1458 12.0821 12.8959 12.0067 12.5797L10.9733 8.25658L14.413 5.35244C14.662 5.13923 14.5337 4.73485 14.1943 4.70544L9.66072 4.33048L7.88803 0.257332C7.7598 -0.0441106 7.32228 -0.0441106 7.19405 0.257332L5.42136 4.33048L0.887817 4.70544C0.55591 4.73485 0.42013 5.13923 0.676603 5.35244L4.11637 8.25658L3.08293 12.5797C3.00749 12.8959 3.36203 13.1458 3.64868 12.9767L7.54104 10.6828Z" fill="#FFF2E3" />
                         </svg>
 
                       </Box>
                     </Box>
                   </Grid>
                   <Grid item xs={12} sm={12} md={7} lg={7} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '16px' }}>
                     
                      
                      {profileData?.profile_data?.profile_details.skills &&
                       profileData.profile_data.profile_details.skills.map((item:{name:string}) => {
                         return <Box className="chips-preview">
                           {item.name}
                         </Box>
                       })
                     }
                   </Grid>
                 </Grid>
                 <Divider style={webStyle2.dividerStyle} />
 
                 </>
 }
 
 export const AboutWorkOpp=({workOppertunityData,SkillButton,workData,downloadProjectPdf,renderLocations,formatDate,handleAbutWorkOpen,accordian,getRateTypeLabelProposal}:any)=>{
   return  <Accordion style={{ background: "transparent", color: "white", fontFamily: "inter", }}>
   <AccordionSummary
   onClick={()=>{
     handleAbutWorkOpen()
   }}
   data-test-id="AccordionSummary"
     aria-controls="panel1-content"
     id="panel1-header"
     style={{
       fontSize: "20px",
       fontWeight: 700,
       lineHeight: "24.2px",
       textAlign: "left",
       color: "#fff2e3",
       paddingLeft: "0px"
     }}
   >
     <Box style={{ display: "flex", alignItems: "center" }}>
       <Typography style={proposalStyle.secondHeadingStyle}>About Work Opportunity</Typography>
       <Box
         style={{
           backgroundColor: "#7d7d7d",
           borderRadius: "50%",
           padding: "4px",
           display: "flex",
           alignItems: "center",
           justifyContent: "center",
         }}
       >
       { !accordian ? <ExpandMoreIcon style={{ color: "#fff2e3", fontSize: "16px" }} />:
         <ExpandLessIcon style={{ color: "#fff2e3", fontSize: "16px" }}/>}
       </Box>
     </Box>
   </AccordionSummary>
   <AccordionDetails style={{paddingInline:0,paddingBottom:0}}>
     <Box style={{ display: 'flex', width:'100%' }}>
       <Box style={{ width:'60%', borderRight:"1px solid rgba(84, 84, 84, 1)", }}>
         <Box>
           <Box style={webStyle2.paddingBox}>
             <Typography
               style={webStyle2.descriptionTitle}
               data-testId="workdescription"
             >
               {" "}
               Work description
             </Typography>
             <Box style={webStyle2.container}>
               <Typography
                 style={{
                   ...webStyle2.description,
                   wordWrap: "break-word",
                   overflowWrap: "break-word",
                   whiteSpace: "pre-line",
                 }}
               >
                 {workOppertunityData?.attributes?.description}
               </Typography>
             </Box>
           </Box>
           <Divider style={webStyle2.dividerStyle} />
           <Box
             style={{
               ...webStyle2.btnBox,
               boxSizing: "border-box",
               flexDirection: "column",
             }}
           >
             <Typography style={{ ...webStyle2.skillTag }}>
               Skill tags
             </Typography>
             <Box
               style={{ ...webStyle2.btnContainer, flexWrap: "wrap" }}
             >
               {workOppertunityData?.attributes?.skills.map(
                 (skill:{id:number,name:string}) => {
                   return (
                     <SkillButton
                       key={skill.id}
                     >
                       {skill.name}
                     </SkillButton>
                   );
                 }
               )}
             </Box>
           </Box>
           {workOppertunityData?.attributes?.deliverables.length > 0 && <><Divider style={webStyle2.dividerStyle} />
             <Box style={webStyle2.paddingBox}>
               <Typography style={webStyle2.skillTag}>
                 Deliverables
               </Typography>
               <Box
                 style={{width:'100%',height:'auto',
                   wordBreak: 'break-word',
 
                 }}
               >
                   <Typography
                  style={{
                   ...{
                       ...webStyle2.listItem,
                       wordWrap: "break-word",
                       overflowWrap: "break-word",
                       whiteSpace: "pre-line",
                   },
                   width: "100%",
                   minHeight: "auto",
                   maxHeight: "100%",
                   overflowY: "auto",
               }}
               
                 dangerouslySetInnerHTML={{ __html: workOppertunityData.attributes.deliverables }}
                />
                 </Box>
             </Box></>}
           {workData.length > 0 && (
             <>
               <Divider style={webStyle2.dividerStyle} />
               <Box style={{ padding: "50px 30px 50px 0px",}}>
                 <Box>
                   <Typography style={webStyle2.descriptionTitle}>
                     Project references
                   </Typography>
                   <Box
                     style={{
                       ...webStyle2.DeliverablesList,
                       display: "flex",
                       flexDirection: "column",
                       gap: "10px",
                     }}
                   >
                     {workData.map((item: any, index: number) => (
                       <Box key={index} data-testId="workProject">
                         <Box
                           style={{
                             display: "flex",
                             alignItems: "baseline",
                             gap: "3px",
                           }}
                         >
                           <Typography style={webStyle2.listNumber}>
                             {item.number}.
                           </Typography>
                           <Box>
                             <Box style={{ marginLeft: "10px" }}>
                               {item.type === "fileName" && (
                                 <>
                                   {item.fileName &&
                                     item.fileName.map(
                                       (fileName: any, fileIndex: number) => (
                                         <Typography
                                         data-test-id="downloadProjectPdf"
                                           onClick={() => downloadProjectPdf(fileName, config.baseURL + item.fileUrl)}
                                           key={fileIndex}
                                           style={{
                                             ...webStyle2.listItem1,
                                             wordBreak: "break-word",
                                             whiteSpace: "pre-wrap",
                                           }}
                                         >
                                           {fileName}
                                         </Typography>
                                       )
                                     )}
                                 </>
                               )}
                             </Box>
 
                             {item.type === "link" && (
                               <Typography component={"a"} href={item.link} target="_blank"
                                 style={webStyle2.listItem2}
                               >
                                 {item.link}
                               </Typography>
                             )}
 
                             {item.type === "project" && (
                               <Box style={webStyle2.DeliverItem3}  >
                                 <Box >
                                   <img
                                     height="281.67px"
                                     src={item.image}
                                     width="415.09px"
                                     alt={item.title}
                                     style={webStyle2.listImg}
                                   />
                                   <Box
                                     style={webStyle2.refContainer}
                                   >
                                     <Box>
                                       <Typography
                                         style={webStyle2.imgTitle}
                                       >
                                         {item.title}
                                       </Typography>
                                       <Box
                                         style={{
                                           display: "flex",
                                           alignItems: "center",
                                           marginTop: "4px",
                                           gap: "10px",
                                         }}
                                       >
                                         <Button
                                           style={{
                                             ...webStyle2.refBtn,
                                             fontSize: "11px",
                                             textTransform: "none",
                                           }}
                                         >
                                           club
                                         </Button>
                                         <Typography
                                           style={webStyle2.imgUser}
                                         >
                                           {item.username}
                                         </Typography>
                                       </Box>
                                     </Box>
                                     <Box
                                       style={webStyle2.imgeContainer}
                                     >
                                       <Box
                                         style={webStyle2.imageIcon}
                                       >
                                         <Typography
                                           style={
                                             webStyle2.iconNumber
                                           }
                                         >
                                           {item.views}
                                         </Typography>
                                         <img
                                           src={eyeIcon}
                                           alt="view"
                                           width="24.2px"
                                           height="17.6px"
                                         />
                                       </Box>
                                       <Box
                                         style={webStyle2.imageIcon}
                                       >
                                         <Typography
                                           style={
                                             webStyle2.iconNumber
                                           }
                                         >
                                           {item.likes}
                                         </Typography>
                                       
                                       </Box>
                                       <Box
                                         style={webStyle2.imageIcon}
                                       >
 
                                         <Typography
                                           style={
                                             webStyle2.iconNumber
                                           }
                                         >
                                           {item.savedCount}
                                         </Typography>
                                         <img
                                           src={featureIcon}
                                           height="22px"
                                           alt="save"
                                           width="24px"
                                         />
                                       </Box> </Box> </Box> </Box>
                               </Box>
                             )}
                           </Box>
                         </Box>
                       </Box>
                     ))}
                   </Box>
                 </Box>
               </Box>
             </>
           )}
         </Box>
       </Box>
       <Divider style={webStyle2.dividerStyle} />
       <Box style={{ width:'40%' }}>
         <Box
           style={{ ...webStyle2.workBox, 
           
             flexDirection: "column" }}
         >
           <Box>
             <Typography style={webStyle2.titleStyle}>
               Work type
             </Typography>
             <Box style={{ marginTop: "14px" }}>
               <Button
                 style={{
                   ...webStyle2.workBtn,
                   textTransform: "none",
                   minWidth: "142px",
                 }}
               >
                 {workOppertunityData?.attributes?.work_type}
               </Button>
             </Box>
           </Box>
           <Box>
             <Box style={webStyle2.location}>
               {/* <img src={location} /> */}
               <Typography style={webStyle2.titleStyle}>
                 Location
               </Typography>
             </Box>
             <Box style={{display:'flex', gap:10, flexWrap:'wrap', marginTop:20, paddingRight:50}}>
              {renderLocations()}
             </Box>
           </Box>
           <Box>
             <Typography style={webStyle2.titleStyle}>
               Experience level
             </Typography>
             <Typography style={webStyle2.description}>
               {workOppertunityData?.attributes?.experience_level}
             </Typography>
           </Box>
           <Typography style={webStyle2.titleStyle}>
             {
               workOppertunityData?.attributes
                 ?.required_hours_per_week
             }{" "}
             Hours pr/week
           </Typography>
           <Box style={webStyle2.btnHourBox}>
             <Typography style={webStyle2.priceHour}>
               Rs. {workOppertunityData?.attributes?.rate_amount}{" "}
               {
                 getRateTypeLabelProposal()
               }
             </Typography>
             <Typography style={webStyle2.month}>
               {workOppertunityData?.attributes?.project_timeline}{" "}
               {
                 workOppertunityData?.attributes
                   ?.project_timeline_type
               }{" "}
             </Typography>
           </Box>
           <Box>
             {workOppertunityData?.attributes?.start_date ? (
               <>
                 <Typography style={webStyle2.titleStyle}>
                   Start date
                 </Typography>
                 <Typography style={webStyle2.description}>
                   {workOppertunityData.attributes.start_date &&
                     formatDate(
                       workOppertunityData.attributes.start_date
                     )}
                 </Typography>
               </>
             ) : null}
           </Box>
         </Box>
       </Box>
     </Box>
   </AccordionDetails>
 </Accordion>
 }
 