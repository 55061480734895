// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import storage from "../../../../framework/src/StorageProvider.web";
import React, { RefObject } from "react";
import  { getCroppedImgData, getBase64Image, fileToDataURL, compressedImageData } from "../../../../components/src/Base64Conversion";

export const configJSON = require("../config");

let tokenData = "";
let accountId = 0;
export interface Props {
  navigation: string;
  id: string;
  activeTab?:string
}

interface ListType {
  id: string | number,
  name: string
}

interface ListFileType {
  data: any
  filename: string,
  file: File
}

interface S {
  message: string,
  isCollectionOpen: boolean,
  isCategoryOpen: boolean,
  action: string,
  collection: ListType,
  category: ListType,
  title: string,
  coverImage: File[],
  profileImage: ListFileType[],
  openCreateCollection: boolean,
  description: string,
  selectedSkillTags: ListType[],
  isFileLoading: boolean,
  loadedImage: number,
  showCropper: boolean,
  crop: { x: number; y: number };
  zoom: number;
  croppedAreaPixels: {
    x: number;
    y: number;
    width: number;
    height: number;
  } | null;
  croppedImage: string|null|any,
  createCollection: boolean,
  newCollection: string,
  collectionList: ListType[],
  categoryList: ListType[],
  skillList: ListType[],
  open: boolean,
  searchText: string,
  searchSkillList: ListType[],
  coverImageText: string,
  isAPIInvoked: boolean,
}

interface SS {
  id: string;
}

export default class DiscoverProjectController extends BlockComponent<
  Props,
  S,
  SS
> {
  delayTimer: any;
  refElm: RefObject<HTMLInputElement>;
  refElmFile: RefObject<HTMLInputElement>;
  textareaRef: RefObject<HTMLDivElement>;
  apiCategoryCallId: string = "";
  apiCollectionCallId: string = "";
  apiSkillsCallId: string = "";
  apiSearchSkillsCallId: string = "";
  apiPostCollectionCallId: string = "";
  apiCreateShareProjectCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    this.state = {
      message: "",
      isCategoryOpen: false,
      isCollectionOpen: false,
      action: "",
      collection: {id: "", name: ""},
      category: {id: "", name: ""},
      title: "",
      coverImage: [],
      profileImage: [],
      openCreateCollection: false,
      description: "",
      selectedSkillTags: [],
      isFileLoading: false,
      loadedImage: 0,
      showCropper: false,
      crop: { x: 0, y: 0 },
      zoom: 1,
      croppedAreaPixels: null,
      croppedImage: null,
      createCollection: false,
      newCollection: "",
      collectionList: [],
      categoryList: [],
      skillList: [],
      open: false,
      searchText: '',
      searchSkillList: [],
      coverImageText: '',
      isAPIInvoked: false,
    };
    this.refElm = React.createRef<HTMLInputElement>();
    this.refElmFile = React.createRef<HTMLInputElement>();
    this.textareaRef = React.createRef();
    this.delayTimer = null;
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiCategoryCallId) {
          this.handleCategoryList(message);
        } else if (apiRequestCallId === this.apiCollectionCallId) {
          this.handleCollectionList(message);
        } else if (apiRequestCallId === this.apiSkillsCallId) {
          this.handleSkillsList(message);
        } else if (apiRequestCallId === this.apiSearchSkillsCallId) {
          this.handleSearchSkillsList(message);
        } else if (apiRequestCallId === this.apiPostCollectionCallId) {
          this.handlePostCollectionList(message);
        } else if (apiRequestCallId === this.apiCreateShareProjectCallId) {
          this.handleCreateProject(message);
        }
      }
    }
  }

  handleErrorMSg = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorData = responseJson.errors[0] || responseJson.errors.error;
    this.setState({
      open: true,
      message: Object.values(errorData).join(),
      action: "danger"
    });
  }

  handleUploadImageClick = () =>{
    if(this.refElm.current !== null){
      this.refElm.current.click()
    }
  }

  handleUploadFileClick = () =>{
    if(this.refElmFile.current !== null){
      this.refElmFile.current.click()
    }
  }

  handleCreateProject = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      this.setState(() => ({
        ...this.state,
        open: true,
        action: "success",
        message: "Project created successfully"
      }), () => {       
        setTimeout(() => {        
          this.redirectTo('LandingPage');
        }, 1000); 
      });
    } else {
      this.handleErrorMSg(message);
    }
  }

  handleSearchSkillsList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      const dataVal = responseJson.data || '';
      if (dataVal) {
        this.setState({ searchSkillList: dataVal.map((item: {id: string | number, name: string}) => ({ id: item.id, name: item.name })) });
      }
    } else {
      this.handleErrorMSg(message);
    }
  }

  handleCategoryList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      const dataVal = responseJson.data || '';
      if (dataVal) {
        this.setState({ categoryList: [...dataVal.map((item: {id: string | number, name: string}) => ({id: item.id, name: item.name }))]});
      }
    } else {
      this.handleErrorMSg(message);
    }
  }

  handleCollectionList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      const dataVal = responseJson.data || '';
      if (dataVal) {
        this.setState({ collectionList: [...(dataVal.collections || [])]})
      }
    } else {
      this.handleErrorMSg(message);
    }
  }

  handlePostCollectionList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      const dataVal = responseJson.data || '';
      if (dataVal) {
        this.setState({
          open: true,
          action: "success",
          message: "Collection created successfully",
          collectionList: [dataVal, ...this.state.collectionList],
          isCollectionOpen: false,
          openCreateCollection: false
        });
      }
    } else {
      this.handleErrorMSg(message);
    }
  }

  handleSkillsList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      const dataVal = responseJson.data || '';
      if (dataVal) {
        this.setState({ skillList: [...dataVal.map((item: {id: string | number, name: string}) => ({id: item.id, name: item.name }))]});
      }
    } else {
      this.handleErrorMSg(message);
    }
  }

  handleDragOver = (event: any) => {
    event.preventDefault();
  };

  onCropComplete = (croppedArea: any, croppedAreaPixel: any) => {
    this.setState({ croppedAreaPixels: croppedAreaPixel });
  };

  handleZoomChange = (zoom: number) => {
    this.setState({ zoom });
  };

  handleZoomChangeFromSlider = (event: React.ChangeEvent<{}>, value: number | number[] ) => {
    this.setState({ zoom: value as number });
  };

  loadedData = (loaded: number) => {
    this.setState({ loadedImage: loaded });
  }

  handleCropChange = (crop: { x: number; y: number }) => {
    this.setState({ crop });
  };

  handleURL = (file: ListFileType) => {
    return file.data || URL.createObjectURL(file.file);
  }

  handleDrop = async (event: any, fieldData?:string) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      if(!this.validFileFormat(file)) {
        if(this.refElm.current) {
          this.refElm.current.value = '';
        }
        return;
      }
      const maxFileSizeMB = 2;
      if (file.size > maxFileSizeMB * 1024 * 1024) {
        this.setState({
          open: true,
          action: "danger",
          message: "File size exceeds 2MB. Please upload a smaller file."
        });
        if(this.refElm.current) {
          this.refElm.current.value = '';
        }
        return;
      }
      const compressedFile = await compressedImageData(file, 0, 0, 0.7);
      if (fieldData) {
        this.setState({ coverImage: [compressedFile], showCropper: true, coverImageText:  compressedFile.name});
      } else {
        this.setState({ 
          isFileLoading: true, 
          profileImage: [...this.state.profileImage, {data: "", filename: file.name, file: compressedFile}] 
        });
        const urlData: any = await fileToDataURL(compressedFile);
        await getBase64Image(urlData, this.loadedData);
        this.setState({
          isFileLoading: false,
          profileImage: [...this.state.profileImage.filter(item => item.data), {data: urlData || '', filename: file.name, file: compressedFile}]
        });
      }
      if(this.refElm.current) {
        this.refElm.current.value = '';
      }
    }
  };

  validFileFormat = (fileData: File) => {
    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    if (!validImageTypes.includes(fileData.type)) {
      this.setState({
        open: true,
        message: "Please select a valid image file (jpg, png).",
        action: "danger"
      });
      return false;
    }
    return true
  }

  handleNewCollection = (val: string) => {
    if(val.length <= 15){
      this.setState({ newCollection: val });
    }
  }

  openCreateCollection = () => {
    this.setState({ openCreateCollection: true, isCollectionOpen: false });
  }

  closeCreateCollection = () => {
    this.setState({ openCreateCollection: false, newCollection: '' });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  redirectTo = (url: string) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), url);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async getDataList() {
    this.commonGetApiCall('collection', configJSON.getCollectionEndPoint);
    this.commonGetApiCall('category', configJSON.getCategoryEndPoint);
    this.commonGetApiCall('skills', configJSON.getSkillEndPoint);
  }

  handleTitleChange = (data: string) => {
    this.setState({title: data})
  }

  handleDescriptionChange = (data: string) => {
    if (this.textareaRef.current) {
      const textareaContainer = this.textareaRef.current;
      const textarea = textareaContainer.querySelector('textarea');
      if (textarea) {
        textareaContainer.style.height = 'auto';
        if (textarea.scrollHeight > 97) {
          textareaContainer.style.height = '97px';
          textareaContainer.style.overflowY = 'auto';
        } else {
          textareaContainer.style.height = data ? `${textareaContainer.scrollHeight}px` : '51px';
          textareaContainer.style.overflowY = 'hidden';
        }
        textareaContainer.style.minHeight = "51px";
      }
    }
    this.setState({description: data})
  }

  showEditCover = () => {
    return this.state.croppedImage ? '': 'd-none';
  }

  toggleCollectionMenu = (event: any) => {
    this.setState( { isCollectionOpen : !this.state.isCollectionOpen, isCategoryOpen: false });
    event.stopPropagation();
  }

  handleClassess = () => {
    return this.state.selectedSkillTags.length ? 'search-field' : 'search-field h-63';
  }

  toggleCategoryMenu = (event: any) => {
    this.setState( { isCategoryOpen : !this.state.isCategoryOpen, isCollectionOpen: false });
    event.stopPropagation();
  }

  handleCollection = (data: ListType) => {
    this.setState({
        isCollectionOpen: false,
        collection: data,
    })
  }

  handleCroppedImage = async () => {
    if (this.state.coverImage.length) {
      const dataUrl:any = await fileToDataURL(this.state.coverImage[0]);
      const resData:any = await getCroppedImgData(dataUrl, this.state.croppedAreaPixels);
      const imageData = await fileToDataURL(resData);
      this.setState({ showCropper: false, zoom: 1, croppedImage:  imageData, coverImage: []});
    }
  };

  commonGetApiCall = (variableName: string, urlEndPoint: string) => {
    const headerObj = {
      "token": tokenData,
      "Content-Type": "application/json"
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (variableName === "category") {
      this.apiCategoryCallId = requestMessage.messageId;
    }
    if (variableName === "collection") {
      this.apiCollectionCallId = requestMessage.messageId;
    }
    if (variableName === "skills") {
      this.apiSkillsCallId = requestMessage.messageId;
    }
    if (variableName === 'searchSkills') {
      this.apiSearchSkillsCallId = requestMessage.messageId;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  commonPostApiCall = (variableName: string, urlEndPoint: string, bodyData: string) => {
    const headerObj = {
      "token": tokenData,
      "Content-Type": "application/json"
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (variableName === "createProject") {
      this.apiCreateShareProjectCallId = requestMessage.messageId;
    }
    if (variableName === "createCollection") {
      this.apiPostCollectionCallId = requestMessage.messageId;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      bodyData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiPostMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCategory = (data: ListType) => {
    this.setState({
        isCategoryOpen: false,
        category: data,
    })
  }

  handleEditCover = () => {
    if (this.refElm.current) {
        this.refElm.current.click()
    }
  }

  handleCoverImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ coverImage: [], showCropper: false });
    if (event.target.files) {
      const tempFile = event.target.files[0];
      if (!this.validFileFormat(tempFile)) {
        if(this.refElm.current) {
          this.refElm.current.value = '';
        }
        return;
      }
      const maxFileSizeMB = 2;
      if (tempFile.size > maxFileSizeMB * 1024 * 1024) {
        this.setState({
          open: true,
          action: "danger",
          message: "File size exceeds 2MB. Please upload a smaller file."
        });
        if(this.refElm.current) {
          this.refElm.current.value = '';
        }
        return;
      }
      const compressedFile = await compressedImageData(tempFile, 0, 0, 0.7);
      this.setState({
        coverImage: [compressedFile],
        showCropper: true,
        coverImageText:  compressedFile.name
      });
      if(this.refElm.current) {
        this.refElm.current.value = '';
      }
    }
  }
  closePopup = () => {
    this.setState({showCropper: false});
  }

  handleDiscardCroppedImage = () => {
    this.setState({ coverImage: [], zoom: 1, showCropper: false });
  };

  handleProfileImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileData = event.target.files[0];
      if (!this.validFileFormat(fileData)) {
        return;
      }
      const maxFileSizeMB = 2;
      if (fileData.size > maxFileSizeMB * 1024 * 1024) {
        this.setState({
          open: true,
          action: "danger",
          message: "File size exceeds 2MB. Please upload a smaller file."
        });
        return;
      }
      const compressedFile = await compressedImageData(fileData, 0, 0, 0.7);
      this.setState({
        isFileLoading: true,
        profileImage: [...this.state.profileImage, {data: '', filename: fileData.name, file: compressedFile}]
      });
      const urlData: any = await fileToDataURL(compressedFile);
      await getBase64Image(urlData, this.loadedData);
      this.setState({
        isFileLoading: false,
        profileImage: [...this.state.profileImage.filter(item => item.data), {data: urlData || '', filename: fileData.name, file: compressedFile}]
      });
    }
  }

  showProgressbar = (index: number) => {
    if ((index === (this.state.profileImage.length - 1)) && this.state.isFileLoading) {
      return true;
    }
    return false;
  }

  deleteProfile = (data: number) => {
    let dataList = this.state.profileImage.filter((item, index) => index !== data);
    this.setState({ profileImage: [...dataList] });
  }

  collectionExist = () => {
    return this.state.collectionList.find(collection => collection.name.toLocaleLowerCase() === this.state.newCollection.toLocaleLowerCase().trim());
  }

  handleCreateCollection = () => {
    if (this.collectionExist()) {
      this.setState({
        open: true,
        message: `Collection ${this.state.newCollection} already exist.`,
        action: "danger"
      });
      return;
    }
    const urlData = `${configJSON.baseURL.baseURL}/${configJSON.createCollectionEndPoint}?name=${this.state.newCollection}`;
    this.commonPostApiCall('createCollection', urlData, '');
  }

  handleAddSkillTags = (data: ListType) => {
    this.setState({ selectedSkillTags: [data, ...this.state.selectedSkillTags], skillList:  this.state.skillList.filter(item => item.id !== data.id)});
  }

  handleRemoveSkillTags = (data: ListType) => {
    this.setState({ selectedSkillTags: this.state.selectedSkillTags.filter(item => item.id !== data.id), skillList: [data, ...this.state.skillList]});
  }

  handleShowDiscard = () => {
    if (
        this.state.title ||
        this.state.description ||
        this.state.collection.name || 
        this.state.category.name ||
        this.state.selectedSkillTags.length ||
        this.state.croppedImage ||
        this.state.profileImage.length
    ) {
      return true
    } else {
      return false
    }
  }

  enableCreateProject = () => {
    if (
        this.state.title &&
        this.state.collection.name &&
        this.state.category.name &&
        this.state.selectedSkillTags.length &&
        this.state.croppedImage &&
        this.state.profileImage.length &&
        !this.state.isAPIInvoked
    ) {
        return true
    } else {
        return false
    }
  }

  discardChanges = () => {
    this.setState({
        title: "",
        description: "",
        collection: {id: "", name: ""},
        category: {id: "", name: ""},
        selectedSkillTags: [],
        coverImage: [],
        profileImage: [],
        croppedImage: '',
        message: "",
        isCategoryOpen: false,
        isCollectionOpen: false,
        action: "",
        openCreateCollection: false,
        isFileLoading: false,
        loadedImage: 0,
        showCropper: false,
        crop: { x: 0, y: 0 },
        zoom: 1,
        croppedAreaPixels: null,
        createCollection: false,
        newCollection: "",
        open: false,
        searchText: '',
        searchSkillList: []
    });
  }

  createProject = async () => {
    this.setState({
      isAPIInvoked:true
    })
    const bodyData = {project_portfolio: {
      project_name: this.state.title,
      description: this.state.description ? this.state.description : "",
      collection_id: this.state.collection.id,
      category_id: this.state.category.id,
      skills: this.state.selectedSkillTags.map(item => item.id),
      cover_image: {data: this.state.croppedImage.replace(/data\:image\/jpeg\;base64\,/gmi, ''), filename: this.state.coverImageText},
      file_images: this.state.profileImage.map(item => ({data: item.data.replace(/data\:image\/jpeg\;base64\,/gmi, ''), filename: item.filename})),
      account_id: accountId
    }};
    this.commonPostApiCall('createProject', `${configJSON.baseURL.baseURL}/${configJSON.createProject}`, JSON.stringify(bodyData));
  }

  hidemenu = () => {
    this.setState({
      isCategoryOpen: false,
      isCollectionOpen: false
    });
  }

  handleChange = (data: string) => {
    this.setState({ searchText: data });
    // Clear previous delay timer
    clearTimeout(this.delayTimer);
    // Set delay for the next event
    this.delayTimer = setTimeout(() => {
      // Make API call only if searchTerm is not empty
      if (data.trim() !== '' && data.trim().length >= 2) {
        this.search(data.trim());
      } else {
        this.setState({ searchSkillList: [] })
      }
    }, 300); // Delay time: 300 milliseconds
  };

  search = (data: string) => {
    this.commonGetApiCall('searchSkills', `${configJSON.searchSkillEndPoint}?search_term=${data}`);
  };

  async componentDidMount() {
    super.componentDidMount();
    const userInfo = await storage.get("userInfo");
    if (userInfo) {
      let storeData = JSON.parse(userInfo);
      if (storeData && storeData.meta) {
        tokenData = storeData.meta.token;
        accountId = storeData.meta.id
        this.getDataList();
      }
    }
  }
}

// Customizable Area End