// Customizable Area Start
import React, { createRef } from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { viewIcon, likeIcon2, likedIcon, fillLikeIcon, AddInspirationIcon, eyeIcon, featureIcon2, backBtn, likeIcon, featureIcon, closeIcon, chatIcon, followIcon, followingIcon, deleteIcon, infoIcon, solidShareIcon, profileIcon } from "../assets";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
export const configJSON = require("../config");
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import storage from "../../../../framework/src/StorageProvider.web";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { Box, Input, Button, Typography, Link as MuiLink } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { setStorageData } from "framework/src/Utilities";

const customStyle = require('./customStyle.js');

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const CustomTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            backgroundColor: "transparent",
            color: "#FFF2E3",
            border: "none",
            fontFamily: "Inter, sans-serif",
            fontSize: "16px",
            lineHeight: "19.36px",
            textAlign: "left",
            borderBottom: "none",
            '&::placeholder': {
                color: "#676767 !important",
                opacity: 1
            }
        },
        '&::before': {
            borderBottom: "none"
        },
        '&::after': {
            borderBottom: "none"
        },
        '&:hover:not(.Mui-disabled)::before': {
            borderBottom: "none !important"
        },
        '&.comment-field': {
            width: "80% !important",
            height: "80px",
            borderTopLeftRadius: "37.47px",
            borderBottomLeftRadius: "37.47px"
        },
        '&.comment-field .MuiInputBase-input, &.replyTextElm .MuiInputBase-input': {
            color: "#fff !important",
            paddingLeft: "26px",
            paddingRight: "26px",
            scrollbarWidth: "none"
        },
        '&.replyTextElm': {
            width: "90% !important",
            height: "56px",
            borderRadius: "37.47px",
            backgroundColor: "#222",
            marginLeft:"-65px"
        },
        '&.inspiration-text .MuiInputBase-input': {
            fontFamily: "Inter-SemiBold,sans-serif",
            fontSize: "14px",
            lineHeight: "16.94px",
            marginLeft: "20px"
        },
        '&.inspiration-text .MuiInputBase-input::placeholder': {
            color: "#666 !important",
            fontStyle: "italic"
        }
    }
})(Input);

const CustomBox = withStyles((theme) => ({
    root: {
        '&.indicators': {
            width: "8px",
            height: "8px",
            border: "1px solid #fff2e3",
            backgroundColor: "#171717",
            cursor: "pointer"
        },
        '&.CursorPointers':{
            cursor: "pointer"
        },
        '&.indicators.active': {
            backgroundColor: "#fff"
        },
        '&.card-section': {
            borderTop: "1px solid #5F5F5F",
            marginTop: "270px",
            paddingTop: "57px",
            paddingLeft: "80px",
            paddingRight: "20px",
            paddingBottom: "50px"
        },
        '& .comment-text': {
            marginTop: "12px",
            color: "#8c8c8c !important"
        },
        '& .commented-by-user': {
            width: "52.98px",
            height: "52.98px",
            borderRadius: "50%"
        },
        '&.commented-user': {
            display: "flex",
            gap: "24px",
            width:"86%"
        },
        '&.delete-comment': {
            borderRadius: "50%",
            backgroundColor: "#545454",
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        '&.delete-comment img': {
            width: "13.9px",
            height: "13.9px"
        },
        '&.action-elm': {
            display: "flex",
            gap: "16px",
            alignItems: "center",
            fontFamily: "Inter-SemiBold,sans-serif",
            fontSize: "16px",
            lineHeight: "13.12px",
            color: "#fff2e3",
            cursor: "pointer"
        },
        '&.action-elm .text-data': {
            fontFamily: "Inter-SemiBold,sans-serif",
            fontSize: "16px",
            lineHeight: "19.36px !important",
            width: "max-content",
            borderBottom: "1px solid #fff2e3",
            color: "#fff2e3"
        },
        '&.action-container-elm': {
            display: "flex",
            borderRadius: "12px",
            backgroundColor: "#222",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "48px",
            padding: "48px 10px",
            width: "160px",
            height: "max-content"
        },
        '&.commented-data-user': {
            display: "flex",
            flexDirection: "column",
            gap: "25px",
            maxWidth: "1084px"
        },
        '&.commented-data': {
            display: "flex",
            gap: "25px",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: "1084px"
        },
        '&.comment-section': {
            borderTop: "1px solid #5F5F5F",
            paddingTop: "59px",
            marginTop: "94px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        '& .card-container': {
            marginTop: "27px !important",
            marginLeft: "0 !important",
            marginRight: "0 !important",
            display: "flex",
            columnGap: "46px",
            rowGap: "55px",
            flexWrap: "wrap"
        },
        '&.comment-container': {
            marginBottom: "68px",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            width: "100%",
            maxWidth: "1084px",
            marginLeft:"-35px"
        },
        '& .cancel-btn': {
            fontFamily: "Inter-SemiBold,sans-serif",
            fontSize: "16px",
            lineHeight: "19.36px",
            color: "#fff",
            width: "max-content",
            cursor: "pointer",
            borderBottom: "1px solid #fff"
        },
        '&.project-image-container': {
            width: "1008px",
            height: "775px",
            marginBottom: "24px",
            marginTop: "24px",
            position: "relative",
            display: "flex",
            justifyContent: "center"
        },
        '&.new-comment-container':{
            width: "1008px",
            marginBottom: "24px",
            marginTop: "24px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginLeft:"-70px"
        },
        '&.new-user-comment-container':{
            width: "1008px",
            marginBottom: "24px",
            marginTop: "24px",
            display: "flex",
            alignItems:"center",
            flexDirection: "row",
            marginLeft:"-70px"
        },
        '&.new-user-comment-reply-container':{
            width: "104%",
            marginBottom: "24px",
            marginTop: "24px",
            display: "flex",
            flexDirection: "row",
            marginLeft:"-74px"
        },
        '&.reply-comment-container-new':{
            maxWidth:"810px",display:"flex",alignItems:"center",marginTop:"-4%"
        },
        '&.project-image-container img': {
            width: "100%",
            height: "100%"
        },
        '& .card-style': {
            maxWidth: "100%",
            height: "260px",
            borderRadius: "10px",
            width: "380px",
            marginBottom: "8px"
        },
        '& .project-title': {
            paddingRight: "10px"
        },
        '& .d-none': {
            display: "none"
        },
        '& .card-style img': {
            width: "100%",
            height: "100%",
            borderRadius: "10px"
        },
        '& .title-container': {
            width: "380px !important"
        },
        '&.like-views-container .likeViews-elm-container': {
            flexWrap: "nowrap",
            overflow: "auto",
            scrollbarWidth: "none",
            MsOverflowStyle: "none",
            display: "flex",
            flexDirection: "column",
            gap: "23px",
            marginTop: "40px",
            width: "505px",
            maxHeight: "525px"
        },
        '& .heading-text': {
            fontFamily: "Inter-SemiBold,sans-serif",
            fontSize: "32px",
            lineHeight: "24px",
            letterSpacing: "0.38px",
            color: "#fff2e3",
            marginBottom: "52px"
        },
        '&.user-info-container': {
            display: "flex",
            justifyContent: "space-between"
        },
        '& .user-details': {
            display: "flex",
            gap: "20px"
        },
        '& .user-name': {
            fontFamily: "Inter-Medium,sans-serif",
            fontSize: "24px",
            lineHeight: "29.05px",
            color: "#fff2e3",
            marginBottom: "10px"
        },
        '&.follow-section': {
            display: "flex",
            gap: "12px"
        },
        '&.follow-section .text-data-elm,&.getin-touch-section .get-in-touch,& .user-collection,& .reply-btn': {
            fontFamily: "Inter-SemiBold,sans-serif",
            fontSize: "16px",
            lineHeight: "19.36px",
            color: "#fff2e3",
            borderBottom: "1px solid #fff2e3",
            width: "max-content",
            cursor: "pointer"
        },
        '& .reply-btn': {
            marginLeft: "8px",
            height: "max-content"
        },
        '& .commented-at': {
            marginRight: "8px",
            marginBottom: "0 !important"
        },
        '& .commented-by': {
            marginRight: "16px",
            color: "#ffffff !important"
        },
        '&.getin-touch-section .get-in-touch': {
            lineHeight: "24px !important",
            letterSpacing: "0.38px !important"
        },
        '&.gap-80': {
            gap: "80px",
            display: "flex"
        },
        '&.left-arrow,&.right-arrow': {
            position: "absolute",
            width: "53px",
            height: "53px",
            bottom: "20%"
        },
        '&.right-arrow': {
            transform: "rotate(180deg)",
            right: "-16%"
        },
        '&.left-arrow': {
            left: "-16%"
        },
        '& .description-label': {
            maxWidth: "1008px",
            marginBottom: "16px",
            color: "#8d8d8d !important"
        },
        '& .max-w-1008': {
            maxWidth: "1008px"
        },
        '&.right-text': {
            fontFamily: "Inter,sans-serif",
            fontSize: "16px",
            lineHeight: "19.36px",
            color: "#8d8d8d",
            display: "flex",
            gap: "9px",
            alignItems: "flex-end"
        },
        '& .default-text': {
            fontFamily: "Inter,sans-serif",
            fontSize: "16px",
            lineHeight: "19.36px",
            color: "#fff2e3"
        },
        '& .default-text-reply-comment': {
            fontFamily: "Inter,sans-serif",
            fontSize: "16px",
            lineHeight: "19.36px",
            color: "#fff2e3",
            textAlign:"justify",
            wordWrap: "break-word",
            wordBreak: 'break-word',
            width:"94%"
        },
        '& .default-text-new': {
            fontSize: "16px",
            fontFamily: "Inter,sans-serif",
            color: "#fff2e3",
            lineHeight: "19.36px",
            maxWidth:"740px",
            marginRight:"15px",
            textAlign:"justify",
            wordWrap: "break-word"
        },
        '& .manage-gap': {
            columnGap: "19px"
        },
        '& .user-profile-pic': {
            width: "76px",
            height: "76px",
            borderRadius: "50%"
        },
        '&.user-data-container': {
            display: "flex",
            alignItems: "center",
            gap: "8px"
        },
        '& .active-text,&.like-views-container .active-text': {
            borderBottom: "1px solid !important",
            borderImageSource: "linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)",
            backgroundImage: "linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)",
            WebkitBackgroundClip: "text",
            MozBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
            borderImageSlice: 1,
            borderImageRepeat: "round",
            fontFamily: "Inter-SemiBold, sans-serif"
        },
        '&.discover-projectDetails-container .MuiDialog-paper': {
            maxWidth: "100% !important"
        },
        '&.project-details-container': {
            width: "425px",
            padding: "32px",
            gap: "44px",
            borderRadius: "24px",
            backgroundColor: "#1D1D1D",
            alignItems: "flex-start",
            paddingTop: "0px !important"

        },
        '&.project-details-container .header-container': {
            display: "flex",
            width: "100%",
            position : 'sticky',
            top : 0,
            backgroundColor: "#1D1D1D",
            zIndex : 8000,
            paddingTop: "32px",
        },
        '&.project-details-container .heading-text': {
            fontFamily: "Inter-SemiBold,sans-serif",
            fontSize: "20px",
            lineHeight: "24.2px",
            color: " #fff2e3",
            marginBottom: "0 !important"
        },
        '&.project-details-container .subheading-text': {
            fontFamily: 'Inter,sans-serif',
            fontSize: "12px",
            lineHeight: "14.52px",
            color: "#7d7d7d",
            marginTop: "11px",
            marginBottom: "44px"
        },
        '&.project-details-container .column-heading': {
            fontFamily: 'Inter,sans-serif',
            fontSize: "14px",
            lineHeight: "16.94px",
            color: "#7d7d7d"
        },
        '&.project-details-container .column-text': {
            fontFamily: "Inter-Medium,sans-serif",
            fontSize: "16px",
            lineHeight: "19.36px",
            color: "#fff2e3"
        },
        '&.project-details-container .text-container': {
            textAlign: "center",
            paddingRight: "17px",
            borderRight: "1px solid #545454",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "11px",
            maxWidth: "72px",
            width: "100%"
        },
        '&.project-details-container .text-container:last-child': {
            borderRight: "none"
        },
        '&.project-details-container .project-elm-container': {
            display: "flex",
            flexDirection: "column",
            gap: "57px",
            width: "100%"
        },
        '&.project-details-container .tags-container': {
            marginTop: "20px",
            display: "flex",
            gap: "12px",
            flexWrap: "wrap"
        },
        '&.like-views-container': {
            padding: "34px 50px 34px 33px !important",
            alignItems: "flex-start !important",
            flexWrap: "wrap"
        },
        '&.like-views-container .header-container': {
            display: "flex",
            alignItems: "center"
        },
        '&.like-views-container .userprofile': {
            width: "55px",
            height: "55px",
            borderRadius: "50%",
            marginRight: "20px"
        },
        '&.like-views-container .text-container': {
            fontFamily: "Inter,sans-serif",
            fontSize: "16px",
            lineHeight: "19.36px",
            color: "#fff2e3"
        },
        '&.like-views-container .right-text-container.active-text': {
            borderBottom: "1px solid",
            marginRight: "0 !important"
        },
        '&.like-views-container .text-data': {
            fontFamily: "Inter-SemiBold,sans-serif",
            fontSize: "20px",
            lineHeight: "24.2px",
            color: "#fff2e3"
        },
        '&.inspiration-container .add-inspiration': {
            display: "flex",
            alignItems: "center",
            gap: "10px"
        },
        '&.inspiration-container .add-inspiration .default-text': {
            fontFamily: "Inter-SemiBold,sans-serif !important",
            color: "#fff2e3",
            borderBottom: "1px solid #fff2e3",
            width: "max-content",
            cursor: "pointer"
        },
        '&.inspiration-container .element-container': {
            marginLeft: "36px",
            marginRight: "38px"
        },
        '&.inspiration-container .header-container': {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        '&.inspiration-container .select-board-level': {
            marginTop: "21px",
            marginBottom: "10px",
            fontFamily: "Inter,sans-serif",
            fontSize: "14px",
            lineHeight: "16.94px",
            color: "#7d7d7d"
        },
        '&.inspiration-container .board-elm-container': {
            width: "421px",
            borderRadius: "10px",
            border: "0.67px solid #2F2F2F",
            maxHeight: "219px",
            minHeight: "50px",
            overflowY: "auto",
            scrollbarWidth: "none",
            MsOverflowStyle: "none"
        },
        '&.inspiration-container .board-elm-container .board-elm': {
            height: "86px",
            borderBottom: "0.67px solid #323232",
            display: "flex",
            alignItems: "center",
            paddingLeft: "13px",
            paddingRight: "30px"
        },
        '&.inspiration-container .board-elm-container .board-elm .color-container': {
            width: "77.95px",
            height: "51.74px",
            borderRadius: "8.05px"
        },
        '&.inspiration-container .board-elm-container .board-elm .text-container': {
            fontFamily: "Inter-SemiBold,sans-serif",
            fontSize: "14px",
            lineHeight: "16.94px",
            color: "#fff2e3",
            maxWidth: "200px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            marginLeft: "18px"
        },
        '&.inspiration-container .board-elm-container .board-elm .image-container': {
            width: "27px",
            height: "27px",
            marginLeft: "auto"
        },
        '&.inspiration-container .board-elm-container .board-elm:first-child': {
            borderRadius: "10px 10px 0 0"
        },
        '&.inspiration-container .board-elm-container .board-elm:last-child': {
            borderRadius: "0 0 10px 10px",
            borderBottom: "none"
        },
        '&.inspiration-container .board-elm-container .board-elm.active': {
            backgroundColor: "#323232"
        },
        '&.inspiration-container .save-inspiration': {
            fontFamily: "Inter-Medium,sans-serif !important",
            width: "99px",
            marginTop: "40px"
        },
        '&.inspiration-container .add-inspiration-data': {
            fontFamily: "Inter-SemiBold,sans-serif",
            fontSize: "16px",
            lineHeight: "19.36px",
            borderBottom: "1px solid #fff2e3",
            color: "#fff2e3",
            marginLeft: "auto",
            cursor: "pointer"
        },
        '&.inspiration-container .heading-text': {
            fontFamily: "Inter-SemiBold,sans-serif",
            fontSize: "20px",
            lineHeight: "24.2px",
            color: "#fff2e3",
            marginBottom: "0 !important"
        },
        '&.inspiration-container,&.like-views-container,&.project-details-container': {
            top: "100px",
            backgroundColor: "#1d1d1d",
            borderRadius: "14.26px",
            zIndex: 9999,
            width: "505px",
            paddingBottom: "30px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center"
        },
        '&.like-views-container .right-text-container': {
            marginLeft: "auto",
            fontSize: "16px",
            lineHeight: "19.36px",
            borderBottom: "1px solid #fff2e3",
            height: "max-content",
            color: "#fff2e3",
            marginRight: "30px"
        },
        '&.inspiration-container .board-elm-container::-webkit-scrollbar,&.like-views-container .likeViews-elm-container::-webkit-scrollbar': {
            width: 0,
            height: 0
        },
        '& .gradient-text': {
            position: "relative",
            background: "linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            BorderBottom:"none !important",
            '&::after': {
                content: '""',
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "1px", 
                background: "linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)",
            },
        },
        '& .follow-unFollow-text-style':{
            textTransform : "capitalize",
            fontFamily : "Inter-SemiBold, sans-serif"
        },
        ...customStyle.customStyle(theme),
        [theme.breakpoints.between(1370, 1460)]: {
            '& .card-style': {
                width: "288px",
                height: "198.4px"
            },
            '& .project-title': {
                maxWidth: "114px",
                paddingRight: "6px"
            },
            '& .manage-gap': {
                columnGap: "10px"
            },
            '& .title-container': {
                width: "288px !important"
            },
            '& .card-label': {
                fontSize: "12px"
            },
            '& .card-text': {
                fontSize: "10px"
            }
        },
        [theme.breakpoints.down(1700)]: {
            '&.project-image-container,& .description-label,& .max-w-1008': {
                maxWidth: "900px",
                maxHeight: "600px"
            },
            '&.new-comment-container':{
                maxWidth: "900px",
            },
            
            '&.left-arrow': {
                left: "-13%"
            },
            '&.right-arrow': {
                right: "-13%"
            },
            '&.gap-80': {
                gap: "40px"
            },
            
           
        },
        [theme.breakpoints.down(1460)]: {
            '&.project-image-container,& .description-label,& .max-w-1008': {
                maxWidth: "800px",
                maxHeight: "500px"
            },
            '&.comment-container':{
                marginLeft:0
            },
            '&.new-comment-container' :{
                maxWidth: "800px",
            },
            
            '& .default-text-new': {
                maxWidth:"650px",
            },
            '&.left-arrow': {
                left: "-13%"
            },
            '&.right-arrow': {
                right: "-13%"
            },
            '&.gap-80': {
                gap: "20px"
            },
            '&.card-container': {
                columnGap: "16px",
                marginLeft: 0
            },
            '&.commented-user':{
                width:"77%"
            },
            '&.new-user-comment-reply-container':{
            width: "720px",
            marginLeft:"-40px"
        },
        },
        '@media (min-width:1920px)': {
            '&.comment-container':{
                marginLeft:"-150px"
            },
            '& .default-text-new': {
                maxWidth:"850px",
            },
            '&.new-user-comment-reply-container':{
            maxWidth: "920px",
            marginLeft:"-40px"
            },
            '&.reply-comment-container-new': {
                maxWidth: "900px"
            },
            '&.commented-user': {
                width: "96%"
            },
        }
    }
}))(Box);

const CustomButton = withStyles({
    root: {
        textTransform: "none",
        '&.add-comment-btn': {
            height: "80px",
            border: "none",
            borderLeft: "1.29px solid #3C3C3C",
            backgroundColor: "transparent",
            borderTopRightRadius: "37.47px",
            borderBottomRightRadius: "37.47px",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            width: "15%",
            '& .comment-data': {
                fontFamily: "Inter-SemiBold,sans-serif",
                fontSize: "16px",
                lineHeight: "19.36px",
                color: "#fff2e3",
                borderBottom: "1px solid #fff2e3"
            }
        },
        '&.button-elm': {
            height: "44px",
            borderRadius: "7px",
            alignItems: "center",
            justifyContent: "center",
            verticalAlign: "middle",
            fontFamily: "Inter-Medium,sans-serif",
            lineHeight: "24px",
            letterSpacing: "0.38px",
            fontSize: "16px",
            display: "flex",
            border: "none",
            gap: "10px",
            backgroundColor: "transparent",
            width: "99px",
            marginTop: "40px"
        },
        '&.button-elm.secondary-btn': {
            backgroundColor: "#fff2e3"
        },
        '&.button-elm.disabled-btn': {
            backgroundColor: "#7d7d7d"
        },
        '&.button-elm.disabled-btn, &.button-elm.secondary-btn': {
            color: "#121212"
        }
    }
})(Button);

const theme = createTheme({
    components: {
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: "#171717",
                    borderRadius: "24px",
                    boxShadow: "0px -6px 34.3px -9px #00000047",
                    paddingLeft: "0",
                    paddingRight: "0",
                    overflow: "auto",
                    scrollbarWidth: "none",
                    MsOverflowStyle: "none",
                    '&::-webkit-scrollbar': {
                        width: 0,
                        height: 0
                    }
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "transparent",
                    marginTop: "100px !important",
                    boxShadow: "none !important"
                },
            },
        }
    },
});

let inspirationList = [
    {
        color: "#FF7373",
        name: "App Design"
    },
    {
        color: "#73ABFF",
        name: "Fashion"
    },
    {
        color: "#73FFCD",
        name: "Website"
    },
    {
        color: "#FF73E0",
        name: "App Design"
    },
    {
        color: "#BFC06F",
        name: "Logo"
    }
];

interface Response {
    id: string | number,
    cover_image_url: string,
    likes: string | number,
    views: string | number,
    project_name: string,
    username: string,
    saved_count: string | number
}
type IDType = string | number | null;
interface Board {
    id:IDType;
    color: string;
    name: string;
  }

interface CommentListType {
    id: string | number,
    comment: string,
    created_at: string,
    updated_at: string,
    commented_by: string,
    userId: string | number,
    profile_photo?: string | null,
    has_reply: boolean,
    commented_time_ago:string | any,
    background_color_code: string | any,
}

export interface ViewsType {
    id: string | number,
    username: string,
    status: string,
    profile_photo: string
}

interface DetailsType {
    id: string | number,
    project_name: string,
    created_at: string,
    views: string | number,
    likes: string | number,
    saves: string | number,
    comments: string | number,
    tags: string[]
}

interface S {
    replyText: string,
    showReply: string | number,
    follow: boolean,
    followFromLike: boolean,
    activeImage: number,
    liked: boolean | string,
    showInspiration: boolean,
    selectedBoard: Board | null,
    selectedBoardId: string | null | number;
    selectedDetails:string | number |null;
    showlikeViews: string,
    showDetails: boolean,
    inspirationText: string,
    addInspiration: boolean,
    selectedColor: string,
    existBoard: { id: string | number, color: string, name: string }[],
    open: boolean,
    message: string,
    action: string,
    selectedProject: string | number,
    projectName: string,
    profilePic: string,
    background_color:string,
    description: string,
    username: string,
    coverImage: string,
    projectImages: string[],
    url: string,
    likes: string | number,
    views: string | number,
    saved_count: string | number,
    collectionName: string,
    commentText: string,
    commentList: CommentListType[],
    replyList: CommentListType[],
    viewsList: ViewsType[],
    createdBy: number | string,
    projectDetails: DetailsType,
    selectedCommentforReply: string | number,
    replyId: string | number
}

export interface Props {
    allprojectList: Response[],
    open: boolean,
    closeModal: (likes:number | string,views:number | string) => void,
    selectedProject: string | number,
    token: string,
    handlenavigation:any
}

interface SS {
    id: string;
}

class ProjectDetails extends BlockComponent<Props, S, SS> {
    userId: string | null;
    viewUserId:string;
    textFieldRef:React.RefObject<HTMLInputElement>;
    apiFollowCallId: string = "";
    apiFollowCallIdFromLike: string = "";
    apiUnFollowCallId: string = "";
    apiUnFollowCallIdFromLike: string = "";
    apiLikeCallId: string = "";
    apiViewsCallId: string = "";
    apiDislikeCallId: string = "";
    apiCommentCallId: string = "";
    apiGetCommentCallId: string = "";
    apiDeleteCommentCallId: string = "";
    apiReplyCallId: string = "";
    apiGetReplyCallId: string = "";
    apiSaveBoardCallId: string = "";
    apiBoardCallId: string = "";
    apiGetBoardCallId: string = "";
    apiGetProjectDetailsCallId: string = "";
    apiGetDetailsCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.state = {
            replyText: '',
            showReply: '',
            follow: true,
            followFromLike: true,
            activeImage: 0,
            liked: '',
            showInspiration: false,
            selectedBoard: { id: "", color: "", name: "" },
            selectedBoardId:null,
            selectedDetails:null,
            showlikeViews: '',
            showDetails: false,
            inspirationText: '',
            addInspiration: false,
            selectedColor: '',
            existBoard: [],
            open: false,
            message: '',
            action: 'success',
            selectedProject: '',
            projectName: '',
            profilePic: '',
            background_color:'',
            description: '',
            username: '',
            coverImage: '',
            projectImages: [],
            url: '',
            likes: 0,
            views: 0,
            saved_count: 0,
            collectionName: '',
            commentList: [],
            replyList: [],
            commentText: '',
            viewsList: [],
            createdBy: '',
            replyId: '',
            selectedCommentforReply: '',
            projectDetails: { id: '', project_name: "", created_at: '', views: '', likes: '', saves: '', comments: '', tags: [] }
        }
        this.textFieldRef = createRef();
        this.userId = '';
        this.viewUserId =""
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.NavigationTargetMessage),
        ];
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.apiCommentCallId) {
                    this.handleProjectComment(message);
                } else if (apiRequestCallId === this.apiReplyCallId) {
                    this.handleCommentReply(message);
                } else if (apiRequestCallId === this.apiFollowCallId) {
                    this.handleFollowUser(message);
                } else if (apiRequestCallId === this.apiUnFollowCallId) {
                    this.handleUnFollowUser(message);
                } else if (apiRequestCallId === this.apiDeleteCommentCallId) {
                    this.handleDeleteComment(message);
                } else if (apiRequestCallId === this.apiLikeCallId) {
                    this.handleLikeUser(message);
                } else if (apiRequestCallId === this.apiDislikeCallId) {
                    this.handleDisLikeUser(message);
                } else if (apiRequestCallId === this.apiBoardCallId) {
                    this.handleCreateBoardData(message);
                } else if (apiRequestCallId === this.apiSaveBoardCallId) {
                    this.handleSaveBoardData(message);
                } 
                this.handleGetRequest(apiRequestCallId, message);
            }
        }
    }

    handleDisLikeUser = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson.errors) {
            this.setState({
                liked: '',
                likes:responseJson.like_count
            });
        } else {
            this.handleErrorMSg(message);
        }
    }

    handleUnFollowUser = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson.errors) {
            this.setState({
                follow: true
            });
        } else {
            this.handleErrorMSg(message);
        }
    }

    handleDeleteComment = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson.errors) {
            const idData = this.state.replyId;
            if (!this.state.selectedCommentforReply) {
                this.setState({
                    commentList: this.state.commentList.filter(comment => comment.id !== idData)
                });
            } else {
                let newCommentList = [...this.state.commentList];
                if (this.state.replyList.length === 1) {
                    newCommentList = this.state.commentList.map(item => {
                        if (item.id === this.state.selectedCommentforReply) {
                            item.has_reply = false;
                        }
                        return item
                    })
                }
                this.setState({
                    showReply: '',
                    replyList: this.state.replyList.filter(comment => comment.id !== idData),
                    commentList: [...newCommentList],
                    replyText: '',
                    replyId: ''
                });
            }
        } else {
            this.handleErrorMSg(message);
        }
    }

    handleGetRequest = (requestID: string, message: Message) => {
        if (requestID === this.apiGetBoardCallId) {
            this.handleGetBoardList(message);
        } else if (requestID === this.apiGetProjectDetailsCallId) {
            this.handleGetProjectList(message);
        } else if (requestID === this.apiGetCommentCallId) {
            this.handleGetCommentList(message);
        } else if (requestID === this.apiGetReplyCallId) {
            this.handleGetReplyList(message);
        } else if (requestID === this.apiGetDetailsCallId) {
            this.handleGetDetailsData(message);
        } else if (requestID === this.apiViewsCallId) {
            this.handleLikeData(message);
        }
    }

    handleLikeData = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        
        if (!responseJson.errors) {
            const data = responseJson.data || [];
    
            const sortedData = data.reduce((acc: any, item: { user_id: number | string, username: string, profile_photo: string, status: string }) => {
                const formattedItem = { id: item.user_id, username: item.username, profile_photo: item.profile_photo, status: item.status };
                if (item.username === this.viewUserId) {
                    acc.unshift(formattedItem);
                } else {
                    acc.push(formattedItem);
                }
                return acc;
            }, []);
    
            this.setState({
                viewsList: sortedData
            });
        } else {
            this.handleErrorMSg(message);
        }
    };

    handleGetDetailsData = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson.errors) {
            const dataVal = responseJson.project || {};
            this.setState({
                showDetails: true,
                projectDetails: {
                    id: dataVal.id,
                    project_name: dataVal.project_name || '',
                    created_at: dataVal.created_at || '',
                    likes: responseJson.likes || 0,
                    views: responseJson.views || 0,
                    saves: responseJson.saves || 0,
                    comments: responseJson.comments || 0,
                    tags: (responseJson.tags || []).map((item: { name: string }) => item.name)
                }
            });
        } else {
            this.handleErrorMSg(message);
        }
    }

    handleGetBoardList = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson.errors) {
            const dataVal = responseJson.data || '';
            if (dataVal) {
                const filteredList = dataVal.map((item: { attributes: { id: number | string, name: string, color_code: string } }) => {
                    let obj = {
                        id: item.attributes.id,
                        name: item.attributes.name,
                        color: item.attributes.color_code
                    }
                    return obj;
                })
                this.setState({
                    existBoard: [...filteredList]
                });
            }
        } else {
            this.handleErrorMSg(message);
        }
    }

    handleGetProjectList = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson.errors) {
            const dataVal = responseJson.project && responseJson.project.data && responseJson.project.data.attributes ? responseJson.project.data.attributes : '';
            if (dataVal) {
                this.setState({
                    projectName: dataVal.project_name,
                    profilePic: dataVal.profile_photo,
                    description: dataVal.description,
                    username: dataVal.username,
                    coverImage: dataVal.cover_image_url,
                    projectImages: [...dataVal.project_images],
                    url: dataVal.url,
                    likes: dataVal.likes,
                    views: dataVal.views,
                    saved_count: dataVal.saved_count,
                    collectionName: dataVal.collection_name,
                    createdBy: dataVal.account_id,
                    follow: !responseJson.is_followed,
                    liked: responseJson.is_liked,
                    background_color:dataVal.background_color
                });
            }
        } else {
            this.handleErrorMSg(message);
        }
    }

    handleGetCommentList = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson.errors) {
            const dataVal = responseJson.data || '';
            if (dataVal) {
                const filteredList = this.formattedData(dataVal);
                this.setState({
                    commentList: [...filteredList]
                });
            }
        } else {
            this.setState({
                commentList: []
            });
            this.handleErrorMSg(message);
        }
    }

    handleGetReplyList = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson.errors) {
            const dataVal = responseJson.data || '';
            if (dataVal) {
                const filteredList = this.formattedData(dataVal);
                this.setState({
                    replyList: [...filteredList],
                    showReply: '',
                    replyText: ''
                });
            }
        } else {
            this.handleErrorMSg(message);
        }
    }

    handleSaveBoardData = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson.errors) {
            this.setState({
                selectedBoard: { id: "", name: "", color: "" },
                open: true,
                message: "Board saved successfully",
                action: "success",
                showInspiration: false,
                saved_count: responseJson.save_count
            });
        } else {
            this.handleErrorMSg(message);
        }
    }

    handleCreateBoardData = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson.errors) {
            const dataVal = responseJson.data;
            if (dataVal) {
                let insPirationObj = {
                    id: dataVal.id,
                    name: dataVal.name,
                    color: dataVal.color_code
                }
                this.setState({
                    existBoard: [{ ...insPirationObj }, ...this.state.existBoard],
                    addInspiration: false,
                    inspirationText: '',
                    selectedColor: ''
                });
            }
        } else {
            this.handleErrorMSg(message);
        }
    }

    handleFollowUser = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson.errors) {
            this.setState({
                follow: false
            });
        } else {
            this.handleErrorMSg(message);
        }
    }

    handleLikeUser = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson.errors) {
            this.setState({
                liked: responseJson.data.id,
                likes: Number(this.state.likes) + 1
            });
        } else {
            this.handleErrorMSg(message);
        }
    }

    handleProjectComment = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson.errors) {
            const dataVal = responseJson.data.attributes || '';           
            if (dataVal) {
                let commentObj = {
                    id: dataVal.id,
                    comment: dataVal.comment,
                    created_at: dataVal.created_at,
                    updated_at: dataVal.updated_at,
                    commented_by: dataVal.account_user_name,
                    userId: dataVal.account_id,
                    profile_photo: dataVal.profile_photo || '',
                    has_reply: false,
                    commented_time_ago:dataVal.commented_time_ago || '',
                    background_color_code:dataVal.background_color_code ,
                    user_role: dataVal.user_role
                    
                }
                this.setState({
                    commentList: [{ ...commentObj }, ...this.state.commentList],
                    commentText: ''
                });
            }
        } else {
            this.handleErrorMSg(message);
        }
    }

    handleCommentReply = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson.errors) {
            const dataVal = responseJson.data.attributes || {};
            
            let commentObj = {
                id: dataVal.id,
                comment: dataVal.comment,
                created_at: dataVal.created_at,
                updated_at: dataVal.updated_at,
                commented_by: dataVal.account_user_name,
                userId: dataVal.account_id,
                profile_photo: dataVal.profile_photo || '',
                has_reply: false,
                commented_time_ago  :dataVal.commented_time_ago || '',
                background_color_code:dataVal.background_color_code ,
                user_role: dataVal.user_role

            }
            const newCommentList = this.state.commentList.map(item => {
                if (this.state.selectedCommentforReply === item.id) {
                    item.has_reply = true;
                }
                return item;
            })
            if (Object.keys(dataVal).length) {
                this.setState({
                    replyList: [{ ...commentObj }, ...this.state.replyList],
                    commentList: [...newCommentList],
                    replyText: '',
                    selectedCommentforReply: ''
                });
            }
        } else {
            this.handleErrorMSg(message);
        }
    }

    handleShowInspiration = () => {
        this.setState({
            showInspiration: true
        });
    }

    handleInspirationElm = (data: string) => {
        ;
        
        this.setState({
            inspirationText: data
        })
    }

    handleAddInspirationElm = () => {
        let matchedData = 0;
        for (let index = 0; index < inspirationList.length; index += 1) {
            const matchedDataElm = this.state.existBoard.find(item => item.color === inspirationList[index].color);
            if (!matchedDataElm) {
                matchedData = index;
                break;
            }
        }

        this.setState({
            addInspiration: true,
            selectedBoard: { id: "", color: "", name: "" },
            selectedColor: inspirationList[matchedData].color
        },() => {
            this.scrollToSection("newBoard");
            if (this.textFieldRef.current) {
              this.textFieldRef.current.focus();
            }})
    }

    handleAddedInspirationElm = () => {
        this.setState({ addInspiration: false, inspirationText: '' });
        this.createBoard();
    }

    handleShowlikeViews = (data: string) => {
        if (data === "Views") {
            this.handleViewLikeList(configJSON.viewsDetailsEndPoint);
        } else {
            this.handleViewLikeList(configJSON.likesDetailsEndPoint);
        }
        this.setState({
            showlikeViews: data
        });
    }

    handleCloselikeViews = () => {
        this.setState({
            showlikeViews: '',viewsList:[]
        });
    }

    handleViewModal = () => {
        return this.state.showlikeViews ? true : false;
    }

    handleCloseInspiration = () => {
        this.setState({
            showInspiration: false,
            addInspiration: false,
            selectedBoard: { id: "", color: "", name: "" }
        });
    }

    showReplyBtn = (comment: CommentListType) => {
        return this.state.showReply !== comment.id ? "" : "d-none";
    }

    showReplyViewBtn = (data: boolean) => {
        return data ? "" : "d-none";
    }

    showReplyElm = (id: string | number) => {
        return (this.state.showReply !== id) ? { display: "none" } : { display: "flex", gap: "28px", alignItems: "center" }
    }

    toggleLike = () => {
        if (this.state.liked) {
            this.disLikeProject();
        } else {
            this.likeProject();
        }
    }

    handleLikedData = () => {
        return this.state.liked ? likedIcon : likeIcon2;
    }

    handelNextImg = () => {
        this.setState({
            activeImage: this.state.activeImage < (this.state.projectImages.length - 1) ? this.state.activeImage + 1 : 0
        })
    }

    handelBackImg = () => {
        this.setState({
            activeImage: this.state.activeImage === 0 ? (this.state.projectImages.length - 1) : this.state.activeImage - 1
        })
    }

    handleReplyAdded = async (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLDivElement>, comment: CommentListType) => {
        if (event.key === 'Enter') {
            this.addReply(comment);
            this.setState({
                selectedCommentforReply: comment.id,
                showReply: '',
                replyText: ''
            })
        }
    }

    handleReplyClick = (id: string | number) => {
        this.setState({
            showReply: id,
            selectedCommentforReply: ''
        });
    }

    handleReplyChange = (data: string) => {
        this.setState({
            replyText: data
        });
    }

    handleCancelReply = () => {
        this.setState({
            replyText: '',
            showReply: ''
        });
    }

    toggleFollow = () => {
        if (this.state.follow) {
            this.followProject();
        } else {
            this.unfollowProject();
        }
    }

    toggleFollowFromLike = (id:any,status:string) => {
        if (status==="follow") {
            this.followProjectFromLike(id);
        } else {
            this.unFollowProjectFromLike(id);
        }
    }

    handleActiveImage = (index: number) => {
        return this.state.activeImage === index ? "" : "d-none"
    }

    handleActiveBullet = (index: number) => {
        return this.state.activeImage === index ? "active" : ""
    }

    handleComment = (data: string) => {
        this.setState({
            commentText: data
        })
    }

    handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            this.addComment();
        }
    };

    activeImageData = (index: number) => {
        this.setState({
            activeImage: index
        })
    }
    handleSelectedInspiration = (data: Board) => {
        setStorageData("boardName",data.name);
        this.setState((prevState) => {
            const isSelected = prevState.selectedDetails === data.id;
            return {
                selectedDetails: isSelected ? null : data.id,                
            };
        });
    };           
        handleActiveBtn = () => {
          return this.state.selectedDetails ? "secondary-btn" : "disabled-btn";
        };    
        btnStatus = () => {
            return !this.state.selectedDetails;
    };    

      
    selectedInspiration = (data: { id:string|number ,color: string, name: string }) => {
        return this.state.selectedDetails === data.id ? "active" : ""
    }

    CheckedInspiration = (data: { id:string|number ,color: string, name: string }) => {
        return this.state.selectedDetails === data.id   ? followingIcon : followIcon
    }

    handleActiveText = (data: ViewsType) => {
        return data.status === "following" ? "gradient-text" : "";
    }

    showDetails = () => {
        this.commonGetApi("details", `${configJSON.baseURL.baseURL}/${configJSON.projectDetailsEndPoint}?id=${this.state.selectedProject}`);
    }

    closeDetails = () => {
        this.setState({ showDetails: false })
    }

    formateCreatedAt = (dateData: string) => {
        const existingDate = moment(dateData, "YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        return existingDate.format('MMM D, YYYY');
    }

    formatTime = (dateData: string) => {
        const existingDate = moment(dateData, "YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        const currentDate = moment();
        const minsDiff = currentDate.diff(existingDate, 'minutes');
        const hoursDiff = currentDate.diff(existingDate, 'hours');
        const daysDiff = currentDate.diff(existingDate, 'days');
        const monthsDiff = currentDate.diff(existingDate, 'months');
        const yearsDiff = currentDate.diff(existingDate, 'years');
        if (yearsDiff > 0) {
            return `${yearsDiff} year ago`;
        }
        if (monthsDiff > 0) {
            return `${monthsDiff} month ago`;
        }
        if (daysDiff > 0) {
            return `${daysDiff} day ago`;
        }
        if (hoursDiff > 0) {
            return `${hoursDiff} hour ago`;
        }
        if (minsDiff > 0) {
            return `${minsDiff} mins ago`;
        }
        return "just now";
    }

    canDeleteCommentReply = (id: string | number, elmID: string | number) => {
        return (this.userId === id) || (this.userId === elmID);
    }

    handleReply = (id: string | number) => {
        return this.state.selectedCommentforReply === id;
    }

    handleErrorMSg = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const errorData = responseJson.errors[0] || responseJson.errors.error;
        this.setState({
            open: true,
            message: Object.values(errorData).join(),
            action: "danger"
        });
    }

    createBoard = () => {
        if (!this.state.inspirationText) {
            this.setState({
                open: true,
                message: "Board name is required",
                action: "danger"
            });
            return;
        }
        const bodyData = {
            "data": {
                "attributes": {
                    "name": this.state.inspirationText,
                    "color_code": this.state.selectedColor
                }
            }
        };
        this.commonPostApiCall("board", configJSON.inspirationEndPoint, JSON.stringify(bodyData));
    }

    followProject = () => {
        const bodyData = {
            "data": {
                "attributes": {
                    "account_id": this.state.createdBy
                }
            }
        };
        this.commonPostApiCall("follow", configJSON.followsEndPoint, JSON.stringify(bodyData));
    }

    followProjectFromLike = (id:any) => {
        const bodyData = {
            "data": {
                "attributes": {
                    "account_id": id
                }
            }
        };
        this.commonPostApiCall("followFromLike", configJSON.followsEndPoint, JSON.stringify(bodyData));
        setTimeout(() => {
            this.handleShowlikeViews(this.state.showlikeViews);
        }, 500);
    }

    unfollowProject = () => {
        this.commonDeleteApiRequest("unfollow", `${configJSON.baseURL.baseURL}/${configJSON.followsEndPoint}/${this.state.createdBy}`);
    }

    unFollowProjectFromLike = (id:any) => {
        this.commonDeleteApiRequest("unFollowFromLike", `${configJSON.baseURL.baseURL}/${configJSON.followsEndPoint}/${id}`);
        setTimeout(() => {
            this.handleShowlikeViews(this.state.showlikeViews);
        }, 500);
    }

    likeProject = () => {
        const bodyData = {
            "data": {
                "attributes": {
                    "likeable_id": this.state.selectedProject,
                    "likeable_type": "BxBlockProjectportfolio::Project"
                }
            }
        };
        this.commonPostApiCall("like", configJSON.likeProjectEndPoint, JSON.stringify(bodyData));
    }

    disLikeProject = () => {
        this.commonDeleteApiRequest("dislike", `${configJSON.baseURL.baseURL}/${configJSON.likeProjectEndPoint}/${this.state.liked}`);
    }

    saveBoard = () => {      
            const bodyData = {
                "data": {
                    "attributes": {
                        "project_id": this.state.selectedProject,
                        "inspiration_id": this.state.selectedDetails
                    }
                }
            };
            this.commonPostApiCall("saveBoard", configJSON.saveInspirationEndPoint, JSON.stringify(bodyData));      
    };

    addComment = () => {
        const bodyData = {
            "comment": {
                "comment": this.state.commentText,
                "commentable_id": this.state.selectedProject,
                "commentable_type": "BxBlockProjectportfolio::Project"
            }
        };
        this.commonPostApiCall("comment", configJSON.commentsEndPoint, JSON.stringify(bodyData));
    }

    handleHeaderData = () => {
        return {
            "token": this.props.token,
            "Content-Type": configJSON.exampleApiContentType
        };
    }

    commonPostApiCall = (variableName: string, urlEndPoint: string, bodyData: string) => {
        const headerObjs = this.handleHeaderData();
        const headerData = JSON.stringify(headerObjs);
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        if (variableName === "follow") {
            this.apiFollowCallId = requestMessage.messageId;
        }
        if (variableName === "followFromLike") {
            this.apiFollowCallIdFromLike = requestMessage.messageId;
        }
        if (variableName === "like") {
            this.apiLikeCallId = requestMessage.messageId;
        }
        if (variableName === "board") {
            this.apiBoardCallId = requestMessage.messageId;
        }
        if (variableName === "saveBoard") {
            this.apiSaveBoardCallId = requestMessage.messageId;
        }
        if (variableName === "comment") {
            this.apiCommentCallId = requestMessage.messageId;
        }
        if (variableName === "reply") {
            this.apiReplyCallId = requestMessage.messageId;
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            urlEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headerData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            bodyData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    commonGetApi = (variableName: string, urlEndPoint: string) => {
        const headerObj = this.handleHeaderData();
        const headerData = JSON.stringify(headerObj);
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        if (variableName === "board") {
            this.apiGetBoardCallId = requestMessage.messageId;
        }
        if (variableName === "comment") {
            this.apiGetCommentCallId = requestMessage.messageId;
        }
        if (variableName === "reply") {
            this.apiGetReplyCallId = requestMessage.messageId;
        }
        if (variableName === "projectDetails") {
            this.apiGetProjectDetailsCallId = requestMessage.messageId;
        }
        if (variableName === "details") {
            this.apiGetDetailsCallId = requestMessage.messageId;
        }
        if (variableName === "views") {
            this.apiViewsCallId = requestMessage.messageId;
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            urlEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headerData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    commonDeleteApiRequest = (variableName: string, urlEndPoint: string) => {
        const headerObj = this.handleHeaderData();
        const headerData = JSON.stringify(headerObj);
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        if (variableName === "comment") {
            this.apiDeleteCommentCallId = requestMessage.messageId;
        }
        if (variableName === "dislike") {
            this.apiDislikeCallId = requestMessage.messageId;
        }
        if (variableName === "unfollow") {
            this.apiUnFollowCallId = requestMessage.messageId;
        }
        if (variableName === "unFollowFromLike") {
            this.apiUnFollowCallIdFromLike = requestMessage.messageId;
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            urlEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headerData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiDeleteMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    addReply = (comment: CommentListType) => {
        const bodyData = {
            "comment": {
                "comment": this.state.replyText,
                "commentable_id": this.state.showReply,
                "commentable_type": "BxBlockComments::Comment"
            }
        };
        this.commonPostApiCall("reply", configJSON.commentsEndPoint, JSON.stringify(bodyData));
    }

    formattedData = (data: { attributes: { id: string | number, comment: string, created_at: string, updated_at: string, account_user_name: string, account_id: string | number, has_reply: boolean, profile_photo?: string | null ,commented_time_ago?:string | null,background_color_code:string|null,user_role:string | null} }[]) => {
        const filteredList = data.map((item: { attributes: { id: string | number, comment: string, created_at: string, updated_at: string, account_user_name: string, account_id: string | number, has_reply: boolean, profile_photo?: string | null ,commented_time_ago?:string | null,background_color_code:string|null, user_role:string|null} }) => {
                
            let obj = {
                id: item.attributes.id,
                comment: item.attributes.comment,
                created_at: item.attributes.created_at,
                updated_at: item.attributes.updated_at,
                commented_by: item.attributes.account_user_name,
                userId: item.attributes.account_id,
                has_reply: item.attributes.has_reply,
                profile_photo: item.attributes.profile_photo,
                commented_time_ago:item.attributes.commented_time_ago,
                background_color_code:item.attributes.background_color_code,
                user_role:item.attributes.user_role
            }
            return obj;
        });
        return filteredList;
    }

    getReplyList = (id: string | number) => {
        this.setState({
            selectedCommentforReply: id
        });
        this.commonGetApi("reply", `${configJSON.baseURL.baseURL}/${configJSON.commentsEndPoint}/${id}`);
    }

    deleteComment = async (id: string | number, commentData?: CommentListType) => {
        this.setState({
            replyId: id,
            selectedCommentforReply: commentData ? commentData.id : ''
        })
        this.commonDeleteApiRequest("comment", `${configJSON.baseURL.baseURL}/${configJSON.commentsEndPoint}/${id}`);
    }

    handleViewLikeList = (endPoint: string) => {
        this.commonGetApi("views", `${configJSON.baseURL.baseURL}/${endPoint}?project_id=${this.state.selectedProject}`);
    }

    getBoardList = () => {
        this.commonGetApi("board", `${configJSON.baseURL.baseURL}/${configJSON.inspirationEndPoint}`);
    }

    getCommentList = () => {
        this.commonGetApi("comment", `${configJSON.baseURL.baseURL}/${configJSON.commentsList}?project_id=${this.state.selectedProject}`);
    }

    handleSelectedProject = () => {
        this.commonGetApi("projectDetails", `${configJSON.baseURL.baseURL}/${configJSON.discoverList}/${this.state.selectedProject}`);
    }

    handleListData = () => {
        this.getCommentList();
        this.handleSelectedProject();
        this.getBoardList();
    }

    handleProjectSelection = (data: string | number) => {
        this.setState({
            selectedProject: data,
            selectedCommentforReply: '',
            projectImages: []
        }, () => this.handleListData());
    }

    checkFollowText = () => {
        return this.state.follow ? <Typography className="text-data-elm" style={{ alignSelf: "flex-end" }}>Follow</Typography> : <Typography className="active-text" style={{ alignSelf: "flex-end" }}>Following</Typography>
    }

    handleImageURL = (item: ViewsType) => {
        return item.profile_photo ? configJSON.baseURL.baseURL + '' + item.profile_photo : profileIcon;
    }

    handleVisibility = () => {
        return this.state.addInspiration ? "none" : "block";
    } 

    HanndleImageComment =(comment :any)=>{
        return (
            < div data-testId="NavigationDiv" onClick={()=>this.props.handlenavigation(comment,this.userId)}>
            {comment.profile_photo && 
                     <img src={`${configJSON.baseURL.baseURL + comment.profile_photo}`} className="commented-by-user" alt="commented by user icon" />
            }
            {(!comment.profile_photo && comment.background_color_code) && (
                <div className="commented-by-user" style={{background:`${comment.background_color_code}`}}></div>
            )

            }
            {(!comment.profile_photo && !comment.background_color_code) &&
                     <img src={profileIcon} className="commented-by-user" alt="commented by user icon" />
            }
            </div>
        )
    }

    async componentDidMount() {
        const userInfo = await storage.get("userInfo");
        if (userInfo) {
            let storeData = JSON.parse(userInfo);
            this.viewUserId = storeData.data?.attributes.user_name
            if (storeData && storeData.meta?.id) {
                this.userId = storeData.meta.id;
                this.setState({
                    selectedProject: this.props.selectedProject
                }, () => this.handleListData());
            }
        }
    }
    scrollToSection = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      };     

      showCommentList = ()=>{
        return(
            <>
                {this.state.commentList.map((comment, index) => <CustomBox key={`comment_id${comment.id}`} className="commented-data-user">
                                            <CustomBox className="new-user-comment-container">
                                                <CustomBox className="commented-user">
                                                   {this.HanndleImageComment(comment)}
                                                    <CustomBox style={{width:"100%"}}>
                                                        <CustomBox style={{ display: "flex" }}>
                                                            <Typography className="commented-by default-text-new">{this.userId == comment.userId ? "You" : comment.commented_by}</Typography>
                                                            <Typography className="commented-at default-text-new description-label">{comment.commented_time_ago?.replace('commented', '')}</Typography>
                                                            <Typography data-test-id="action-elm" onClick={() => this.handleReplyClick(comment.id)} className={`reply-btn pointer-cursor ${this.showReplyBtn(comment)}`}>Reply</Typography>
                                                            <Typography data-test-id="action-elm" onClick={() => this.getReplyList(comment.id)} className={`reply-btn pointer-cursor ${this.showReplyViewBtn(comment.has_reply)}`}>View Reply</Typography>
                                                        </CustomBox>
                                                        <Box style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                                        <Typography className="comment-text default-text-new" style={{width:"95%"}}>{comment.comment}</Typography>
                                                        {this.canDeleteCommentReply(comment.userId, this.state.createdBy) && <CustomBox data-test-id="delete-comment" onClick={() => this.deleteComment(comment.id)} className="delete-comment"><img src={deleteIcon} alt="delete comment" /></CustomBox>}
                                                        </Box>
                                                    </CustomBox>
                                                </CustomBox>
                                            </CustomBox>
                                            {this.handleReply(comment.id) && this.state.replyList.map((reply) => 
                                            <CustomBox className="reply-comment-container-new">
                                                <CustomBox className="new-user-comment-reply-container">
                                                {this.HanndleImageComment(reply)}
                                                
                                                  <CustomBox style={{marginLeft:"20px",marginRight:"20px"}}>
                                                        <Typography className="commented-by default-text-new" style={{display:"flex"}}>{reply.userId == this.userId ? "You":reply.commented_by} <Typography className="commented-at default-text description-label" style={{marginLeft:"16px"}}>{reply.commented_time_ago?.replace('commented', '')}</Typography></Typography>
                                                        
                                                        
                                                    <Typography className="comment-text default-text-reply-comment">{reply.comment}</Typography>
                                                    </CustomBox>
                                                 
                                                </CustomBox>
                                                {this.canDeleteCommentReply(reply.userId, comment.userId) && 
                                                <CustomBox onClick={() => this.deleteComment(reply.id, comment)} className="delete-comment"><img src={deleteIcon} alt="delete comment" /></CustomBox>}
                                            </CustomBox>)}
                                            <CustomBox sx={{
                                                ...this.showReplyElm(comment.id)
                                            }}>
                                                <CustomTextField
                                                    type="text"
                                                    className="default-text replyTextElm "
                                                    data-test-id="input-reply-data"
                                                    value={this.state.replyText}
                                                    onChange={(event) => this.handleReplyChange(event.target.value)}
                                                    onKeyPress={(event) => this.handleReplyAdded(event, comment)}
                                                    multiline
                                                    rows={1}
                                                    style={{overflow:"auto"}}
                                                    
                                                />
                                                <Typography data-test-id="cancel-reply" onClick={() => this.handleCancelReply()} className="cancel-btn">Cancel</Typography>
                                            </CustomBox>
                                        </CustomBox>)}
            </>
        )
      }
      HandleProfileImage = (ProfilePhoto:string,background_Color:string) =>{   
        let comment:any = {
            account_id: this.state.createdBy,
            user_role : "designer",
            userId: this.state.createdBy
        }
        if(ProfilePhoto){
            return (
              <img data-testId="ProfilePhotonav" onClick={()=>this.props.handlenavigation(comment,"")} className="user-profile-pic" src={ configJSON.baseURL.baseURL + '' + ProfilePhoto}/>
            )
        } else if(background_Color){
    
            return(
                <Box data-testId="backgroundcolornav" onClick={()=>this.props.handlenavigation(comment,"")} className="user-profile-pic" style={{backgroundColor:`${background_Color}`}}></Box>
            )
        } else {
            return(
                  <img data-testId="staticImgnav" onClick={()=>this.props.handlenavigation(comment,"")} className="user-profile-pic"  src={profileIcon} alt='profileicon'/>
        )}
      }
    render() {
        const isMorethanOneImage = this.state.projectImages && this.state.projectImages.length >1
        return (

            <ThemeProvider theme={theme}>
                <Dialog
                    open={this.props.open}
                    data-test-id="close-modal-icon"
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                    classes={{ paper: "data-container" }}
                    maxWidth={false}
                    fullWidth={true}
                    fullScreen={true}
                    TransitionComponent={Transition}
                >
                    <DialogContent dividers={true} >
                        <DialogContentText>
                            <CustomBox className="discover-projectDetails-container">
                                <CustomBox style={{ display: "flex" }}><img  onClick={() => this.props.closeModal(this.state.likes,this.state.views)} src={closeIcon} alt="close modal" style={{ marginRight: "24px", marginLeft: "auto" ,cursor:"pointer"}} /></CustomBox>
                                <CustomBox style={{ marginTop: "64px", display: "flex", flexDirection: "column", alignItems: "center" }}><CustomBox>
                                    <Typography className="heading-text">{this.state.projectName}</Typography>
                                    <CustomBox className="gap-80">
                                        <CustomBox>
                                            <CustomBox className="user-info-container">
                                            <section id="section1" style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>
                                                <CustomBox className="user-details"  >
                                                   {this.HandleProfileImage(this.state.profilePic,this.state.background_color)}
                                                    <CustomBox>
                                                        <Typography className="user-name">{this.state.username}</Typography>
                                                        <CustomBox style={{ display: "flex", gap: "27px" }} >
                                                            <CustomBox className="user-data-container getin-touch-section">
                                                                <img src={chatIcon} alt="getin touch" />
                                                                <Typography className="get-in-touch">Get in touch</Typography>
                                                            </CustomBox>
                                                            <CustomBox className="follow-section" onClick={() => this.toggleFollow()}>
                                                                <img style={{ width: "27px", height: "27px" }} src={this.state.follow ? followIcon : followingIcon} alt="follow " />
                                                                {this.checkFollowText()}
                                                            </CustomBox>
                                                        </CustomBox>
                                                    </CustomBox>
                                                </CustomBox>
                                           </section>
                                                <CustomBox className="right-text">
                                                    Collection:
                                                    <Typography className="user-collection">{this.state.collectionName}</Typography>
                                                </CustomBox>
                                            </CustomBox>
                                            <CustomBox className="project-image-container">
                                                {this.state.projectImages.map((item, index) =>
                                                    <img key={item} className={`${this.handleActiveImage(index)}`} src={`${configJSON.baseURL.baseURL}${item}`} alt="project image" />
                                                )}
                                                {
                                                isMorethanOneImage && (
                                                        <>
                                                    <CustomBox 
                                                    data-test-id="backImgCall"
                                                    onClick={() =>this.state.activeImage > 0 && this.handelBackImg()} 
                                                    className="left-arrow"
                                                    style={{display:this.state.activeImage === 0 ? "none":""}}
                                                    >
                                                    <img src={backBtn} alt="back button" />
                                                    </CustomBox>
                                                    <CustomBox 
                                                    onClick={() => this.state.activeImage < (this.state.projectImages.length - 1) && this.handelNextImg()} 
                                                    data-test-id="rightArrow"
                                                     className="right-arrow"
                                                     style={{display:this.state.activeImage === (this.state.projectImages.length -1) ? "none":""}}
                                                     >
                                                        <img src={backBtn} alt="next button" />
                                                     </CustomBox>
                                                    <CustomBox sx={{
                                                        display: "flex",
                                                        position: "absolute",
                                                        bottom: "10%",
                                                        width: "100%",
                                                        borderRadius: "2px",
                                                        maxWidth: "400px",
                                                        justifyContent: "space-between"
                                                    }}
                                                        style={{ gap: "4px", width: `${this.state.projectImages.length * 25}px` }}
                                                    >
                                                        {this.state.projectImages.map((item, index) =>
                                                            <CustomBox data-test-id="bullet-elm" onClick={() => this.activeImageData(index)} className={`indicators ${this.handleActiveBullet(index)}`} />
                                                        )}
                                                    </CustomBox>
                                                </>)
                                                }
                                            </CustomBox>
                                            {this.state.description && (
                                                <>
                                                    <Typography className="description-label default-text">Description</Typography>
                                                    <Typography className="default-text max-w-1008" style={{ color: "#fff" }}>
                                                        {this.state.description}
                                                    </Typography>
                                                </>
                                            )}

                                        </CustomBox>
                                        <CustomBox className="action-container-elm">
                                            <CustomBox className="action-elm">
                                                <img data-test-id="action-elm" className="margin-right" src={viewIcon} />
                                                <Typography style={{ cursor: "pointer" }} data-test-id="action-elm" onClick={() => this.handleShowlikeViews("Views")}>{this.state.views}</Typography>
                                            </CustomBox>
                                            <CustomBox className="action-elm">
                                                <img data-test-id="action-elm" style={{ width: "32px", height: "26px" }} onClick={() => this.toggleLike()} className="margin-right" src={this.handleLikedData()} />
                                                <Typography style={{ cursor: "pointer" }} data-test-id="action-elm" onClick={() => this.handleShowlikeViews("Likes")}>{this.state.likes}</Typography>
                                            </CustomBox>
                                            <CustomBox className="action-elm">
                                                <img onClick={() => this.handleShowInspiration()} data-test-id="action-elm" style={{ marginRight: "8px", height: "27px" }} src={featureIcon2} />
                                                {this.state.saved_count}
                                            </CustomBox>
                                            <CustomBox className="action-elm">
                                                <img data-test-id="action-elm" className="margin-right" src={solidShareIcon} />
                                                <Typography data-test-id="share-elm" onClick={() => { }} className="text-data">Share</Typography>
                                            </CustomBox>
                                            <CustomBox className="action-elm">
                                                <img data-test-id="action-elm" className="margin-right" src={infoIcon} />
                                                <Typography data-test-id="project-details-dialog" onClick={() => this.showDetails()} className="text-data">Details</Typography>
                                            </CustomBox>
                                        </CustomBox>
                                    </CustomBox>
                                </CustomBox>
                                </CustomBox>
                                <CustomBox className="comment-section">
                                    <CustomBox className="new-comment-container">
                                        <CustomBox className="new-comment-container">
                                            <Typography className="description-label default-text">Comments</Typography>
                                            <CustomBox sx={{
                                                height: "80px",
                                                borderRadius: "37.47px",
                                                border: "1.29px solid #2F2F2F",
                                                display: "flex",
                                            }}>
                                                <CustomTextField
                                                    className="default-text comment-field"
                                                    type="text"
                                                    data-test-id="comment-text-elm"
                                                    value={this.state.commentText}
                                                    onChange={(event) => this.handleComment(event.target.value)}
                                                    multiline
                                                    style={{overflow:"auto"}}
                                                    rows={2} 
                                                    onKeyDown={(event) => this.handleKeyPress(event)}
                                                />
                                                <CustomButton data-test-id="add-comment" className="add-comment-btn" onClick={() => this.addComment()}>
                                                    <Typography className="comment-data">Comment</Typography>
                                                </CustomButton>
                                            </CustomBox>
                                        </CustomBox>
                                       {this.showCommentList()}
                                    </CustomBox>
                                </CustomBox>
                                <CustomBox className="card-section">
                                    <Typography className="default-text description-label">More Work</Typography>
                                    <CustomBox className="card-container">
                                        {this.props.allprojectList.map((item, index) => (
                                            <CustomBox key={item.id}>
                                                <CustomBox className="card-style" onClick={() => this.handleProjectSelection(item.id)}>
                                                <MuiLink
                                                    data-testId="imagesection1"
                                                        href="#section1"
                                                        onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                                                            e.preventDefault();
                                                            this.scrollToSection("section1");
                                                        }}
                                                    >
                                                <Box >
                                                    <img src={`${item.cover_image_url}`} alt="project cover image" />
                                                    </Box>
                                                    </MuiLink>
                                                </CustomBox>
                                                <CustomBox className="title-container" style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <CustomBox className="project-title">
                                                        <Typography className="card-label" title={item.project_name}>{item.project_name}</Typography>
                                                        <Typography className="card-text">{item.username}</Typography>
                                                    </CustomBox>
                                                    <CustomBox className="manage-gap" style={{ display: "flex", height: "24px" }}>
                                                        <CustomBox className="manage-space">
                                                            <Typography className="card-text">{item.views}</Typography>
                                                            <img className="eyeIcon" src={eyeIcon} alt="eye icon" />
                                                        </CustomBox>
                                                        <CustomBox className="manage-space">
                                                            <Typography className="card-text">{item.likes}</Typography>
                                                            <img className="likeIcon" src={likeIcon} alt="like icon" />
                                                        </CustomBox>
                                                        <CustomBox className="manage-space-feature">
                                                            <Typography className="card-text">{item.saved_count}</Typography>
                                                            <img className="featureIcon" src={featureIcon} alt="feature icon" />
                                                        </CustomBox>
                                                    </CustomBox>
                                                </CustomBox>
                                            </CustomBox>
                                        ))}
                                    </CustomBox>
                                </CustomBox>
                            </CustomBox>
                        </DialogContentText>
                    </DialogContent>
                    <Dialog
                        open={this.state.showInspiration}
                        classes={{ paper: "data-inspiration-container" }}
                        TransitionComponent={Transition}
                    >
                        <DialogTitle>
                            <CustomBox data-test-id="close-inspiration" onClick={() => this.handleCloseInspiration()} className="CursorPointers" style={{ position: "absolute", right: "10px" }}><img className="CursorPointers" src={closeIcon} alt="close inspiration" /></CustomBox>
                        </DialogTitle>
                        <DialogContent
                            style={{
                                marginTop: "20px",
                                marginBottom: "24px",
                                paddingTop: 0,
                                paddingBottom: 0,
                                backgroundColor: "transparent",
                                boxShadow: "none"
                            }}
                        >
                            <DialogContentText>
                                <CustomBox className="inspiration-container">
                                    <CustomBox className="element-container">
                                        <CustomBox className="header-container">
                                            <Typography className="heading-text">Save to inspiration</Typography>
                                            <MuiLink
                                                    href="#newBoard"
                                                    onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                                                        e.preventDefault();
                                                        this.handleAddInspirationElm();
                                                    }}
                                                    style={{ textDecoration: 'none' }}
                                                >
                                            <CustomBox className="add-inspiration" onClick={() => this.handleAddInspirationElm()}>
                                                <img src={AddInspirationIcon} alt="add inspiration" />
                                                <Typography className="default-text">New Board</Typography>
                                            </CustomBox>
                                            </MuiLink>
                                        </CustomBox>
                                        <Typography  className="select-board-level">
                                            Select board
                                        </Typography>
                                        <CustomBox className="board-elm-container" >
                                            {!this.state.addInspiration && !this.state.existBoard.length && <CustomBox style={{ color: "#fff2e3", paddingLeft: "15px", paddingTop: "15px", fontFamily: "Inter-Medium,sans-serif" }} className="default-text">No result found</CustomBox>}
                                            {this.state.addInspiration && <CustomBox className="board-elm active"  >
                                                <CustomBox style={{ backgroundColor: this.state.selectedColor }} className="color-container"></CustomBox>
                                                <CustomTextField
                                                    type="text"
                                                    className="inspiration-text"
                                                    placeholder="Type name"
                                                    value={this.state.inspirationText}
                                                    onChange={(event) => this.handleInspirationElm(event.target.value)}
                                                    inputRef={this.textFieldRef}
                                                />
                                                <CustomBox className="add-inspiration-data" onClick={() => this.handleAddedInspirationElm()}>Done</CustomBox>
                                            </CustomBox>}
                                            {this.state.existBoard.map(item => <CustomBox key={`board${item.id}`} className={`board-elm ${this.selectedInspiration(item)}`}>
                                                <CustomBox style={{ backgroundColor: item.color }} className="color-container"></CustomBox>
                                                <Typography className="text-container">{item.name}</Typography>
                                                <CustomBox style={{ display: this.handleVisibility() }} data-test-id="board-selected" className="image-container" onClick={() => this.handleSelectedInspiration(item)}>
                                                    <img className="CursorPointers" style={{cursor:"pointer"}} src={this.CheckedInspiration(item)} alt="select board" />
                                                </CustomBox>
                                            </CustomBox>)}
                                        </CustomBox>
                                    </CustomBox>
                                    <CustomButton  disabled={this.btnStatus()}  onClick={this.saveBoard} className={`button-elm save-inspiration ${this.handleActiveBtn()}`}>Save</CustomButton>
                                </CustomBox>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.showDetails}
                        classes={{ paper: "data-showDetails-container" }}
                        TransitionComponent={Transition}
                    >
                        <DialogContent
                            style={{
                                marginTop: "20px",
                                marginBottom: "24px",
                                paddingTop: 0,
                                paddingBottom: 0,
                                backgroundColor: "transparent",
                                boxShadow: "none",
                                position:'relative'
                            }}
                        >
                            <DialogContentText>
                                <CustomBox className="project-details-container">
                                    <CustomBox className="header-container">
                                        <CustomBox>
                                            <Typography className="heading-text">Project details</Typography>
                                            <Typography className="subheading-text">Posted {this.formateCreatedAt(this.state.projectDetails.created_at)}</Typography>
                                        </CustomBox>
                                        <CustomBox data-test-id="close-details" onClick={() => this.closeDetails()} style={{ marginLeft: "auto" }}><img className="CursorPointers" style={{cursor:"pointer"}} src={closeIcon} alt="close inspiration" /></CustomBox>
                                    </CustomBox>
                                    <CustomBox className="project-elm-container">
                                        <CustomBox style={{ display: "flex", width: "100%", gap: "17px", justifyContent: "space-between" }}>
                                            <CustomBox className="text-container">
                                                <Typography className="column-heading">Views</Typography>
                                                <Typography className="column-text">{this.state.projectDetails.views}</Typography>
                                            </CustomBox>
                                            <CustomBox className="text-container">
                                                <Typography className="column-heading">Likes</Typography>
                                                <Typography className="column-text">{this.state.projectDetails.likes}</Typography>
                                            </CustomBox>
                                            <CustomBox className="text-container">
                                                <Typography className="column-heading">Saves</Typography>
                                                <Typography className="column-text">{this.state.projectDetails.saves}</Typography>
                                            </CustomBox>
                                            <CustomBox className="text-container">
                                                <Typography className="column-heading">Comments</Typography>
                                                <Typography className="column-text">{this.state.projectDetails.comments}</Typography>
                                            </CustomBox>
                                        </CustomBox>
                                        <CustomBox>
                                            <Typography style={{ color: "#fff2e3", fontSize: "14px", lineHeight: "16.94px", fontFamily: "Inter,sans-serif" }}>Tags</Typography>
                                            <CustomBox className="tags-container">
                                                {this.state.projectDetails.tags.map((item: string) => <Typography className="tags tags-active">{item}</Typography>)}
                                            </CustomBox>
                                        </CustomBox>
                                    </CustomBox>
                                </CustomBox>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.handleViewModal()}
                        classes={{ paper: "data-likeViews-container" }}
                        TransitionComponent={Transition}
                    >
                        <DialogTitle>
                            <CustomBox data-test-id="close-view-like" onClick={() => this.handleCloselikeViews()} className="CursorPointers" style={{ position: "absolute", right: "10px" }}><img src={closeIcon} className="CursorPointers" alt="close inspiration" /></CustomBox>
                        </DialogTitle>
                        <DialogContent
                            style={{
                                marginTop: "20px",
                                marginBottom: "24px",
                                paddingTop: 0,
                                paddingBottom: 0,
                                backgroundColor: "transparent",
                                boxShadow: "none"
                            }}
                        >
                            <DialogContentText>
                                <CustomBox className="like-views-container">
                                    <CustomBox className="header-container">
                                        <img src={this.state.showlikeViews === "Likes" ? fillLikeIcon : viewIcon} alt="likes" />
                                        <Typography className="text-data" style={{ paddingLeft: "12px" }}>{this.state.showlikeViews}</Typography>
                                        <Typography className="text-data" style={{ paddingLeft: "8px" }}>({this.state.viewsList.length})</Typography>
                                    </CustomBox>
                                    <CustomBox className="likeViews-elm-container">
                                        {this.state.viewsList.map(item => (<CustomBox style={{ display: "flex", alignItems: "center" }}>
                                            <img style={{ width: "55px", height: "55px", borderRadius: "50%", marginRight: "20px" }} className="profileIcon" src={this.handleImageURL(item)} alt="user profile" />
                                            {
                                                
                                                this.viewUserId == item.username ? <Typography className="text-container">{"You"}</Typography> :<> <Typography className="text-container">{item.username}</Typography>
                                                <Typography data-test-id="likesFollowUnFollow" className={`right-text-container CursorPointer ${this.handleActiveText(item)} follow-unFollow-text-style`} onClick={()=>{this.toggleFollowFromLike(item.id,item.status)}}>
                                                {item.status}
                                            </Typography> </>
                                            }
                                        </CustomBox>))}
                                    </CustomBox>
                                </CustomBox>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </Dialog>
            </ThemeProvider>
        );
    }
}

export default ProjectDetails;

// Customizable Area End