// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  styled,
  Tabs,
  Tab,
  Divider,
  InputAdornment,
  Grid,
  TextField,
  Menu,
  MenuItem,
  Modal
} from "@material-ui/core";

import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import {Settings,Insights,ShareIcon,HeartIcon,EyeIcon,ListIcon,rightArrow} from "./assets";
import Loader from '../../../components/src/Loader.web'
const baseURL = require("../../../framework/src/config.js");
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import PortfolioManagementController, {
  Props,
  IListItem
} from "./PortfolioManagementController";

export default class PortfolioManagement extends PortfolioManagementController {
   
  MainWrapper = styled(Box)(({ theme }) => ({
    "& .tabs_css": {
      color: "#FFF2E3",
      fontWeight: 700,
      fontFamily: "Inter,sans-serif",
      textTransform: 'none',
      fontSize: 16,
      textAlign: 'start',
      paddingLeft:'0px',
      cursor: 'pointer',
    },
    "& .divider_for_tabs": { backgroundColor: '#3E3E3E', width: '100%', borderBottomWidth: 2 },
    '& .MuiTabs-indicator': {
      backgroundColor: "#FFF2E3",
      height: '3px',
      
    },
    '& .Mui-indicator ': {
      justifyContent: 'flex-start',
      alignItems:'start',

    },
    '& .MuiTab-wrapper' :{
      alignItems: "start",
      
    },
    '& .SettingsBtn' :{
      background: 'rgba(23, 23, 23, 1)',
      color: 'rgba(255, 242, 227, 1)',
      borderRadius:"8px",
      borderWidth:'0px',
      display:'flex',
      alignItems: 'center',
      padding:"6px"
    },
    '& .FirstBox' :{
      display: 'flex',
      justifyContent:"space-between",
      alignItems: 'center',
      width:"100%"

    },
    '& .SecondBoxContainer' :{
      marginTop:"25px",
      borderRadius:"10px",
      height:"92px",
      backgroundColor:"black",
      alignContent:"center",
      padding:"0px 24px",
    
    },
    '& .SecondBox' :{
      display: 'flex',
      justifyContent:"space-between",
      alignItems: 'center',
      backgroundColor:"black",
      width:"100%"
      // marginLeft: '10px'
    },
    '& .collection':{
      fontWeight:700,
      color: 'rgba(255, 242, 227, 1)',
      fontSize:"20px",
    },
    '& .CollectionsList' :{
      borderRight:"1px solid white",
      width:"25%",
      display:'flex',
      justifyContent:'center'
      // padding:"0px 40px"
    },
    '& .lastCollectionList' :{
      width:"25%",
      display:'flex',
      justifyContent:'center'
      // padding:'0px 40px'
    },
    '& .lastCollectionListlentghtzero' :{
      width:"25%",
      display:'flex',
      justifyContent:'flex-start'
    },
    '& .CollectionName' :{
      fontWeight:400,
      fontSize:"20px",
      color: 'rgba(140, 140, 140, 1)',
      cursor: 'pointer',

    },
    '& .view-Btn' :{
      width: '24px',
      height: '24px',
      borderRadius: '8px',    
      backgroundColor: '#222222',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      cursor:'pointer',
    },
    '& .backBtn':{
      rotate:'180deg',
      margin:"0 10px"

    },
    '& .cursor-pointer' : {
      cursor: 'pointer',
    },
    
    
    '& .info-container': {
      display: 'flex',
      borderRadius: "10px",
      height: "78px",
      border: '1px solid #6D6D6D',
      width: '500px',
      marginTop: '10px',
      marginBottom: '10px',
      
  },
  '& .Branding':{
    fontSize: '36px',
    fontWeight: 700,
    marginRight: '10px'
    
  },
  '& .label-text': {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '16px',
  color: 'rgba(140, 140, 140, 1)'

},
'& .thirdboxContainer' :{
  display: 'flex',
  justifyContent:"space-between",
  alignItems: 'center',
 padding:"10px 0px"
},
'& .BrandingContainer' :{
  display:'flex',
  alignItems: 'center',
},
'& .shareicon' : {
  width:"25px",
  height:"22px",
  marginLeft:"10px",
  cursor: "pointer",
},
'& .value-text' :{
  fontWeight:600,
  fontSize:'16px',
  fontFamily:"Inter",
  color: 'rgba(255, 242, 227, 1)'

},
'& .fourhContainer' :{

},
"& .main_grid_container":{
       
  margin: "50px auto",
  width:"100%"
},
"& .card_container":{
  width:"100%", height:'100%'
}, "& .card_image":{
  height:"260px", width:"100%", borderRadius:10, cursor: "pointer"
}, "& .title_container":{
  display: "flex", justifyContent: "space-between",marginTop:"10px"
},
'& .project-title': {
  maxWidth: "100px",
  paddingRight: "6px"
},
'& .manage-gap': {
  columnGap: "10px"
},
'& .title-container': {
  width: "264px !important"
},
'& .card-label': {
  fontSize: "12px"
},
'& .card-text': {
  fontSize: "12px"
},
'&.card-label, & .card-text': {
  lineHeight: "16px"
},
'& .manage-space ' :{
  display:"flex",
  alignItems: "center",
  gap:"5px"
},
'& .label-text-Project':{
  fontSize:"16px",
  fontWeight:600,
  color: 'rgba(140, 140, 140, 1)'

},
'& .ActiveHeader' : {
  color: 'transparent',
  fontWeight:700,
  borderWidth:' 0px 0px 2px',
  backgroundColor: 'transparent',
  backgroundImage:'linear-gradient(90.83deg, rgb(63, 167, 225) -0.01%, rgb(118, 153, 239) 31.13%, rgb(174, 157, 242) 51.64%, rgb(232, 165, 202) 72.11%, rgb(241, 178, 198) 99.99%)',
  fontFamily: 'Inter-SemiBold, sans-serif',
  fontSize: '20px',
  lineHeight: '19.36px',
  width: 'max-content',
  textAlign: 'left',
  cursor: 'pointer',
  backgroundClip: 'text'
},
'& .MoreVectorIcon' :{
  textAlign: 'center',
},
'& .MoreVectorIcon svg' :{
  width:"50px",
  height:"50px",
  background:"#171717",
  borderRadius:"50px"
},
'& .no_projects_container' :{
  textAlign: 'center',
  width:"100%"
}



  })
)
  constructor(props: Props) {
    super(props);
  }

  a11yProps = (index: number,activeCard:string) => {
    return ({
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      label: activeCard,
      className:"tabs_css",
    })
  }

  StyledTabs = styled(Tabs)({
    '& .MuiTabs-wrapper': {
      justifyContent: 'flex-start',
      flexDirection: 'row',
      alignItems: "start",
    },
    '& .MuiTab-wrapper' :{
      alignItems: "start",
    },
    '& .MuiTabs-indicator': {
      // backgroundColor: "#FFF2E3",
      // height: '3px',
      width: "100px !important" ,
    
    }
  });

  render() {
    const {CollectionListTabs,metaObj,PortfolioData,PaginationData,currentIndex,itemsPerPage} = this.state
    const displayedItems = CollectionListTabs.slice(1, currentIndex + itemsPerPage);
    
    return (
      <ThemeProvider theme={theme}>
        <this.MainWrapper >
          <Grid container>
          <Box className="FirstBox">
            <Grid xs={9} sm={12} item>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <this.StyledTabs
                  id="tabs"
                  onChange={this.handleTabValueChange}
                  value={this.state.tabsValues}
                  indicatorColor="primary"
                  className="Mui-indicator"
                  style={{borderBottom:"1px solid grey"}}
                >
                  <Tab {...this.a11yProps(0, "Portfolio")} />
                  <Tab {...this.a11yProps(1, "Services")} />
                  <Tab {...this.a11yProps(3, "Inspirations")} />
                  <Tab {...this.a11yProps(4, "Work Experience")} />
                </this.StyledTabs>
              </Box>
             
            </Box>
            </Grid>
            <Grid  xs={3} sm={12} item>
            <Box style={webStyle.sibtnstyles}>
              <button
                
                className="SettingsBtn"
                style={{ marginRight: "4px" }}
              
              >
                <span ><img  style={webStyle.spanImg} src={Settings} alt="" /></span>Settings
              </button>
              <button
                
                className="SettingsBtn"
                style={{ marginLeft: "4px" }}
                
              >
               <span><img style={webStyle.spanImg} src={Insights} alt="" /></span> Insights
              </button>
            </Box>
            </Grid>
          </Box>
          </Grid>
          <Box className="SecondBoxContainer">
            <Box className="SecondBox">
              <div style={{ marginRight:"10px"}}>
                <Typography className="collection">Collections</Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
              <Loader loading={false} />
                {(this.state.currentIndex > 1 && (this.state.currentIndex >1 || displayedItems.length > 0 ) )&& (
                  <button
                  className="view-Btn backBtn"
                  data-testId="showlesstestId"  
                  onClick={() => this.showLessItems()}
                >
                  <img
                    src={rightArrow}
                    width={24}
                    height={24}
                    alt="view history"
                  />
                </button>
                )}
                
              </div>
              <Box
                style={{
                  width: "80%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                 <div
                      
                      className={
                        displayedItems.length>0 ? 
                           "CollectionsList":'lastCollectionListlentghtzero'}
                    >
                      
                      <Typography
                        data-testId="ActiveHeader"
                        className={`${
                          this.state.ActiveCollection == 'All'
                            ? "ActiveHeader"
                            : "CollectionName"
                        }`}
                        onClick={() => {
                          this.handleActivecollection({name:'All',id:'nil'});
                        }}
                      >
                      
                        All({this.handleallcount()})
                      </Typography>
                    </div>
                    {
                    
                      displayedItems.length > 0 &&
                        displayedItems.map((item, index) => (
                          <div
                            key={index}
                            className={
                              index < 3 && index < displayedItems.length - 1
                                ? "CollectionsList"
                                : "lastCollectionList"
                            }
                          >
                            <Typography
                              data-testId="ActiveHeader"
                              className={`${
                                this.state.ActiveCollection == item.name
                                  ? "ActiveHeader"
                                  : "CollectionName"
                              }`}
                              onClick={() => {
                                this.handleActivecollection(item);
                              }}
                            >
                             
                              {item.name}({item.count})
                            </Typography>
                          </div>
                        ))
                      }
                    
                
              </Box>
              <div
                style={{
                  width: "5%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                
                {(this.state.moreBtn && this.state.CollectionListTabs.length >3)  && (
                   <button
                   className="view-Btn"
                   data-testId="showMoretestId"  
                   onClick={() => this.showMoreItems()}
                 >
                   <img
                     src={rightArrow}
                     width={24}
                     height={24}
                     alt="view history"
                   />
                 </button>
                )}
               
              </div>
            </Box>
          </Box>
          <Box className="thirdboxContainer">
            <Box className="BrandingContainer">
              <Typography className="Branding">
                {this.state.ActiveCollection}
              </Typography>
              <div>
                {this.state.ActiveCollection !== 'All' && (
                  <>
                
        <MoreVertIcon onClick={this.handleOpenMenuList} className="cursor-pointer" />
        <Menu
        id="long-menu"
        anchorEl={this.state.anchorEl}
        keepMounted
        open={this.state.Openmenu}
        onClose={this.handleCloseMenuList}
        data-testId="closeMenuTestId"
        PaperProps={{
          style: {
            width: '20ch',
            border: '1px solid rgb(89, 89, 89)',
            color: 'rgba(255, 242, 227, 1)',
            background: 'rgba(17, 17, 17, 1)'
          },
        }}
      >
        <MenuItem data-testId="rename_collectionBtn" onClick={()=>this.handleRenameCollection()}>
         Rename Collection
        </MenuItem>
        <MenuItem data-testId="delete_collectionBtn"  onClick={()=>this.handleDeleteCollection()}>
         Delete Collection
        </MenuItem>
      </Menu>
      <img className="shareicon" src={ShareIcon} alt="" />
      </>
         )} 
       
      </div>
            </Box>
            <Box>
              <div className="info-container">
                <div className="part-data border-right">
                  <div className="info-data">
                    <div className="label-text-Project">Projects</div>

                    <div className="value-text">
                      {this.ProjectCount()}
                     
                      </div>
                  </div>
                </div>
                <div className="part-data border-right">
                  <div className="info-data">
                    <div className="label-text">
                      {" "}
                      <img className="" src={HeartIcon} alt="" />
                    </div>
                    <div className="value-text">
                      {this.LikesCount()}
                    </div>
                  </div>
                </div>
                <div className="part-data border-right p-0">
                  <div className="info-data">
                    <div className="label-text">
                      {" "}
                      <img className="" src={EyeIcon} alt="" />
                    </div>
                    <div className="value-text">
                      {this.viewCount(metaObj)}
                      </div>
                  </div>
                </div>
                <div className="part-data left-text">
                  <div className="info-data">
                    <div className="label-text">
                      {" "}
                      <img src={ListIcon} alt="" />
                    </div>
                    <div className="value-text">
                      <div className="value-text">{this.saveCount()}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Box>
          <Box className="fourhContainer" >
            <Grid container className="main_grid_container" spacing={3} >
            
              {PaginationData?.length > 0 ? (
                PaginationData?.map((item: any, index) => (
                  <Grid
                    item
                    sm={6}
                    xs={6}
                    lg={4}
                    md={6}
                    data-test-id="grid-items"
                    key={index}
                  >
                    <Box className="card_container">
                      <Box>
                        <img
                          className="card_image"
                          src={`${this.baseUrlStirng}${item.attributes.cover_image_url}`}
                          alt="project cover image"
                        />
                      </Box>
                      <Box className="title_container">
                        <Box className="project-title">
                          <Typography
                            className="card-label"
                            title={item.attributes.project_name}
                          >
                            {item.attributes.project_name}
                          </Typography>
                          <Typography className="card-text">
                            {item.attributes.username}
                          </Typography>
                        </Box>
                        <Box
                          className="manage-gap"
                          style={{ display: "flex", height: "24px" }}
                        >
                          <Box className="manage-space">
                            <span className="card-text">
                              {item.attributes.views}
                            </span>
                            <img
                              className="eyeIcon"
                              src={EyeIcon}
                              alt="eye icon"
                            />
                          </Box>
                          <Box className="manage-space">
                            <span className="card-text">
                              {item.attributes.likes}
                            </span>
                            <img
                              className="likeIcon"
                              src={HeartIcon}
                              alt="like icon"
                            />
                          </Box>
                          <Box className="manage-space manage-space-feature">
                            <span className="card-text">
                              {item.attributes.saved_count}
                            </span>
                            <img
                              className="featureIcon"
                              src={ListIcon}
                              alt="feature icon"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))
              ) : (
                <Box className="no_projects_container">
                  <Typography
                    id="no_projects_to_show"
                    className="no_project_text"
                  >
                    No Projects To Show
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>
          
          <Box>
            
             { (this.state.hasMore && PortfolioData.length == 6 ) &&
             <div className="MoreVectorIcon">

             <ExpandMoreRoundedIcon onClick={()=>this.handlePagination()} data-testId="paginationTestid" />
             </div>
             }
             <Loader loading={this.state.isLoading} />  
            
          </Box>
          
          <Modal
            open={this.state.openCollection}
            onClose={this.handleCloseProfile}
            data-testId="proModal"
            aria-labelledby="edit-profile-modal"
            aria-describedby="modal to edit profile"
          >
            <Box sx={webStyle.paperProfile}>
              <Box
                style={{
                  margin: "0 auto",
                  textAlign: "center",
                  padding: "32px",
                }}
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography
                    style={webStyle.modalTitle}
                    data-testId="editprofile"
                  >
                    Rename Collection
                  </Typography>
                  
                </Box>
                <Box>
                <input
                    type="text"
                    id="profile_name"
                    name="name"
                    placeholder="Name"
                    className="inputCommanProfileEdit"
                    style={webStyle.CollectionInput}
                    value={this.state.collection_Name}
                    data-testId= "inputcollectionName"
                    onChange={(e) =>this.handleUpdateCollectionName(e)}
                    
                  />
                </Box>
              
                <Box style={webStyle.btnBox}>
                  <Button
                    data-testId="submitsave"
                    style={{ ...webStyle.canBtn, textTransform: "none" }}
                    onClick={this.handleCloseProfile}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ ...webStyle.saveBtn, textTransform: "none" }}
                    data-testId="saveCollecion"
                    onClick={()=>this.hanleupdateCollectionName()}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </this.MainWrapper>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  spanImg :{
    width:"20px",
    height:"20px",
    margin:"0px 4px"
  },
  sibtnstyles:{
    display:'flex',
    justifyContent:"end",
    flexWrap:'wrap'
  } as React.CSSProperties,
  paperProfile:{
    position: 'absolute',
    width:488,
    backgroundColor: '#222222',
    border: '2px solid #000',
    boxShadow: '5px 5px 20px rgba(0,0,0,0.3)',
    borderRadius: '7px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
   
  },
  modalTitle: {
    fontSize: "20px",
    color: "#FFF2E3",
    fontWeight: 600,
    lineHeight:"24.2px",
    fontFamily: "Inter",   
    width:"100%"     
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "20px",
    paddingBottom: "30px",
    background: "#fff",
  },
  canBtn:{
    fontFamily: "Inter",      
    linehHeigth: "24px",
    fontSize: "16px",
    color: '#FFF2E3',
    fontWeight: 500,
    backgroundColor:'#0D0D0D',       
    padding:"10px 30px 10px 30px",  
    borderRadius:"7px",  
    width:"max-content",     
    },
    btnBox:{marginTop:"40px" ,
     display:"flex",
      gap:"24px" , 
      justifyContent:"center"
    },
    CollectionInput :{
      width:"100%",
      height:"50px",
      textAlign:"center",
      marginTop:"40px"
    } as React.CSSProperties,
    closeIcon:{
      cursor: 'pointer',
      marginLeft: 'auto',
      height: '10px',
      cursar:"pointer",
      width: '10px',
    },
  saveBtn:{
    fontSize: "16px",
    fontFamily: "Inter",      
    backgroundColor:'#FFF2E3',       
    fontWeight: 500,
    linehHeigth: "24px",
    borderRadius:"7px",  
    color: '#121212',
    padding:"10px 30px 10px 30px",      
    width:"max-content",       
       },
  video: {
    height: 200,
    width: 350,
    padding: 20,
    borderRadius: 25,
  },

  elementWrap: {
    backgroundColor: "lightgrey",
  },

  searchfield: {
    backgroundColor: "lightgrey",
    borderRadius: 5,
    padding: 5,
  },

  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  imageGrid: {
    backgroundColor: "lightgrey",
    padding: 20,
    marginTop: 50,
    borderRadius: 10
  },
  imageAll: {
    height: 200,
    width: 200,
    margin: "auto",
    padding: 20,
    borerRadius: 20,
    backgroundColor: "red",
  },
  docOuterGrid: {
    backgroundColor: "lightgrey",
    padding: 20,
    marginTop: 30,
    borerRadius: 15,
  },
  docGrid: {
    padding: 10,
    margin: 10,
  },
  videoOuter: {
    backgroundColor: "lightgrey",
    padding: 20,
    marginTop: 30,
    borerRadius: 20,
  }
};

// Customizable Area End
